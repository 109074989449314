import styled from "@emotion/styled";
import { format } from "date-fns";
import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { QuestionMark } from "@mui/icons-material";
import { heightFromMinutes, timeDisplay } from "../../../../utils/time";
import { TimeDisplay } from "./TimeslotComponent";

const BlankContainer = styled(Box)`
  padding: 4px 12px;
  margin: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  &:hover {
    background-color: #f5f5f5;
  }
`;

interface BlankTimeslotComponentProps {
  startMs: number;
  endMs: number;
  onClick: () => void;
}

export const BlankTimeslotComponent: React.FC<BlankTimeslotComponentProps> = ({
  startMs,
  endMs,
  onClick,
}) => {
  const startTime = format(new Date(startMs), "HH:mm");
  const minutes = (endMs - startMs) / 1000 / 60;
  const verticalHeight = heightFromMinutes(minutes);
  return (
    <BlankContainer onClick={onClick} sx={{ height: verticalHeight + "px" }}>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <Stack direction="column" alignItems="center">
          <TimeDisplay>{startTime}</TimeDisplay>
        </Stack>

        <Stack
          sx={{ flexGrow: 1 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          height="100%"
          gap={2}>
          <QuestionMark />
          <Typography variant="subtitle1" fontWeight={500}>
            No activity
          </Typography>
        </Stack>

        <Typography variant="body2" color="text.secondary">
          {timeDisplay(minutes)}
        </Typography>
      </Stack>
    </BlankContainer>
  );
};
