import React, { useMemo } from "react";
import { Timeslot } from "../../../../database/db";
import { BlankTimeslotComponent } from "./BlankTimeslotComponent";
import { TimeslotComponent } from "./TimeslotComponent";
import styled from "@emotion/styled";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { Stack } from "@mui/material";
import {
  heightFromMinutes,
  hourLabelBackgroundColor,
} from "../../../../utils/time";
import { format } from "date-fns";

const ListContainer = styled(Stack)`
  padding: 16px 16px 0;
  margin-top: 30px;
  margin-bottom: 50px;
`;

const TimeBlock = styled.div<{ height: number; color: string }>`
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color};
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: start;
  justify-content: center;
  transition: height 0.2s ease-out;

  div {
    font-size: 12px;
    color: #666;
    font-weight: bold;
    margin-top: -10px;
    padding: 0 4px;
    opacity: 0.6;
    filter: drop-shadow(1px 1px 1px #fff) drop-shadow(-1px -1px 1px #fff);
    transition: opacity 0.1s linear;
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

interface TimeslotListViewProps {
  dayBounds: [number, number];
  timeslots: Timeslot[];
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  selectedTimeslot: Timeslot | null;
  editingTimeslot: string | null;
  onTimeslotClick: (timeslot: Timeslot) => void;
  onBlankClick: (startMs: number, endMs: number) => void;
  setEditingTimeslot: (timeslotId: string | null) => void;
}

export const TimeslotListView: React.FC<TimeslotListViewProps> = ({
  dayBounds,
  timeslots,
  activityCategoryMap,
  selectedTimeslot,
  editingTimeslot,
  onTimeslotClick,
  onBlankClick,
  setEditingTimeslot,
}) => {
  const timeBlocks = useMemo(() => {
    const blocks: Array<{
      type: "timeslot" | "blank";
      start: number;
      end: number;
      timeslot?: Timeslot;
    }> = [];
    let currentTime = dayBounds[0];

    // Sort timeslots by start time
    const sortedTimeslots = [...timeslots].sort(
      (a, b) => a.startTimestampMills - b.startTimestampMills
    );

    sortedTimeslots.forEach((timeslot) => {
      // Add blank block if there's a gap
      if (timeslot.startTimestampMills > currentTime) {
        blocks.push({
          type: "blank",
          start: currentTime,
          end: timeslot.startTimestampMills,
        });
      }

      // Add timeslot block
      blocks.push({
        type: "timeslot",
        start: timeslot.startTimestampMills,
        end: timeslot.endTimestampMills,
        timeslot,
      });

      currentTime = timeslot.endTimestampMills;
    });

    // Add final blank block if needed
    if (currentTime < dayBounds[1]) {
      blocks.push({
        type: "blank",
        start: currentTime,
        end: dayBounds[1],
      });
    }

    return blocks;
  }, [timeslots, dayBounds]);

  const totalHeight = useMemo(() => {
    return timeBlocks.reduce((acc, block) => {
      const minutes = (block.end - block.start) / 1000 / 60;
      const height = heightFromMinutes(minutes);
      return acc + height;
    }, 0);
  }, [timeBlocks]);

  const hourBlocks = useMemo(() => {
    const blocks: Array<{ hour: number; height: number; color: string }> = [];
    const dayStart = new Date(dayBounds[0]);

    // Calculate height for each hour based on the timeBlocks that intersect with it
    for (let hour = 0; hour < 24; hour++) {
      const hourStart = new Date(dayStart).setHours(hour, 0, 0, 0);
      const hourEnd = new Date(dayStart).setHours(hour + 1, 0, 0, 0);

      // Calculate total height of timeBlocks that intersect with this hour
      let hourHeight = 0;

      timeBlocks.forEach((block) => {
        const blockStart = Math.max(block.start, hourStart);
        const blockEnd = Math.min(block.end, hourEnd);

        if (blockEnd > blockStart) {
          const blockDuration = blockEnd - blockStart;
          const totalBlockDuration = block.end - block.start;
          const blockHeight = heightFromMinutes(
            (block.end - block.start) / 1000 / 60
          );

          hourHeight += (blockDuration / totalBlockDuration) * blockHeight;
        }
      });

      // Determine color based on time of day
      const color = hourLabelBackgroundColor(hour);

      blocks.push({
        hour,
        height: hourHeight,
        color,
      });
    }

    return blocks;
  }, [timeBlocks, dayBounds]);

  console.log(editingTimeslot);

  return (
    <ListContainer direction="row" spacing={2}>
      <Stack
        direction="column"
        height={totalHeight + "px"}
        width="50px"
        sx={{
          backgroundColor: "#eeeeee88",
          borderRadius: 1,
        }}>
        {hourBlocks.map((block) => (
          <TimeBlock key={block.hour} height={block.height} color={block.color}>
            {block.hour > 0 && (
              <div>{format(new Date().setHours(block.hour), "HH:00")}</div>
            )}
          </TimeBlock>
        ))}
      </Stack>

      <Stack direction="column" flexGrow={1}>
        {timeBlocks.map((block, index) => (
          <React.Fragment key={index}>
            {block.type === "timeslot" && block.timeslot ? (
              <TimeslotComponent
                timeslot={block.timeslot}
                activityCategoryMap={activityCategoryMap}
                isSelected={selectedTimeslot?.id === block.timeslot.id}
                isEditing={editingTimeslot === block.timeslot.id}
                onClick={() => onTimeslotClick(block.timeslot!)}
                onDoubleClick={() => {
                  if (
                    editingTimeslot !== block.timeslot?.id &&
                    block.timeslot
                  ) {
                    setEditingTimeslot(block.timeslot.id);
                  } else {
                    setEditingTimeslot(null);
                  }
                }}
              />
            ) : (
              <BlankTimeslotComponent
                startMs={block.start}
                endMs={block.end}
                onClick={() => onBlankClick(block.start, block.end)}
              />
            )}
          </React.Fragment>
        ))}
      </Stack>
    </ListContainer>
  );
};
