import {
  Button,
  Container,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useSettings } from "../hooks/useSettings";
import {
  getLocationHistory,
  processAndInsertLocationHistory,
  updateLocationHistoryEndpoint,
} from "../utils/location_history_processing";
import { useSnackbar } from "../hooks/useSnackbar";
import { StartAnalyticsScreen } from "../hooks/SettingsProvider";
import { isValidUrl } from "../utils/url";
import { useCloudSyncedDB } from "../hooks/useCloudSyncedDb";
import { useModal } from "../hooks/useModal";
import WarningIcon from "@mui/icons-material/Warning";
import { MATERIAL_COLOURS } from "../const/colours";
import { clearDatabase, setAppInitialised } from "../utils/db_helpers";
import { JSONDisplay } from "./home/components/JsonDisplay";

const snapToMillsOptions = [
  {
    label: "1 minute",
    value: 1000 * 60,
  },
  {
    label: "5 minutes",
    value: 1000 * 60 * 5,
  },
  {
    label: "10 minutes",
    value: 1000 * 60 * 10,
  },
  {
    label: "15 minutes",
    value: 1000 * 60 * 15,
  },
  {
    label: "20 minutes",
    value: 1000 * 60 * 20,
  },
  {
    label: "30 minutes",
    value: 1000 * 60 * 30,
  },
  {
    label: "1 hour",
    value: 1000 * 60 * 60,
  },
];

const defaultTimeslotDurationOptions = [
  {
    label: "10 minutes",
    value: 1000 * 60 * 15,
  },
  {
    label: "15 minutes",
    value: 1000 * 60 * 15,
  },
  {
    label: "30 minutes",
    value: 1000 * 60 * 30,
  },
  {
    label: "1 hour",
    value: 1000 * 60 * 60,
  },
  {
    label: "2 hours",
    value: 1000 * 60 * 60 * 2,
  },
];

const startAnalyticsScreenOptions = [
  {
    label: "Recents",
    value: "recents",
  },
  {
    label: "Trends",
    value: "trends",
  },
  {
    label: "Calendar",
    value: "calendar",
  },
];

const dateFormatOptions = [
  // Full formats with day name
  {
    label: "Monday, 31 January 2024",
    value: "EEEE, d MMMM yyyy",
  },
  {
    label: "Mon, 31 Jan 2024",
    value: "EEE, d MMM yyyy",
  },
  {
    label: "31 Jan 2024, Mon",
    value: "d MMM yyyy, EEE",
  },

  // Standard formats with full month
  {
    label: "31 January 2024",
    value: "d MMMM yyyy",
  },
  {
    label: "January 31, 2024",
    value: "MMMM d, yyyy",
  },

  // Abbreviated month formats
  {
    label: "31 Jan 2024",
    value: "d MMM yyyy",
  },
  {
    label: "Jan 31, 2024",
    value: "MMM d, yyyy",
  },

  // Ordinal formats
  {
    label: "31st January 2024",
    value: "do MMMM yyyy",
  },
  {
    label: "January 31st, 2024",
    value: "MMMM do, yyyy",
  },

  // Numeric formats (European)
  {
    label: "31/01/2024",
    value: "dd/MM/yyyy",
  },
  {
    label: "31-01-2024",
    value: "dd-MM-yyyy",
  },
  {
    label: "31.1.2024",
    value: "d.M.yyyy",
  },

  // Numeric formats (American)
  {
    label: "01/31/2024",
    value: "MM/dd/yyyy",
  },
  {
    label: "01-31-2024",
    value: "MM-dd-yyyy",
  },

  // ISO and technical formats
  {
    label: "2024-01-31",
    value: "yyyy-MM-dd",
  },
  {
    label: "2024/01/31",
    value: "yyyy/MM/dd",
  },

  // Short formats without year
  {
    label: "31 January",
    value: "d MMMM",
  },
  {
    label: "Monday, January 31st",
    value: "EEEE, MMMM do",
  },
];

const MODAL_CLEAR_DB_BODY = (
  <>
    <Typography variant="body1" gutterBottom>
      The server you are connecting to has existing data.
    </Typography>
    <Typography variant="body1" gutterBottom>
      Proceeding will clear your local database and import the data from the
      server.
    </Typography>
    <Typography variant="body1" gutterBottom fontWeight="bold" color="error">
      This action cannot be undone, make sure to back up your data before
      proceeding.
    </Typography>
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{ fontStyle: "italic", mt: 2 }}>
      If you wish to keep your local data instead, please clear the data on the
      server's settings page before connecting.
    </Typography>
  </>
);

const MODAL_UPLOAD_DB_BODY = (
  <>
    <Typography variant="body1" gutterBottom>
      You are about to upload your local database to the server.
    </Typography>

    <Typography variant="body1" gutterBottom>
      This might take a while depending on the size of your database.
    </Typography>
  </>
);

export const Settings = () => {
  const {
    showLocation,
    snapToMills,
    setShowLocation,
    setSnapToMills,
    defaultTimeslotDuration,
    setDefaultTimeslotDuration,
    settingsAsJson,
    defaultZoom,
    setDefaultZoom,
    locationHistoryEndpoint,
    setLocationHistoryEndpoint,
    cloudBackupEndpoint,
    startAnalyticsScreen,
    setStartAnalyticsScreen,
    cloudSyncEnabled,
    enableCloudSync,
    disableCloudSync,
    setLastCloudChangeTimestamp,
    autoSyncLocationHistory,
    autoSyncBackups,
    setAutoSyncLocationHistory,
    setAutoSyncBackups,
    syncBatchSize,
    setSyncBatchSize,
    developerMode,
    setDeveloperMode,
    dateFormat,
    setDateFormat,
  } = useSettings();

  const { showSnackbar } = useSnackbar();

  const [snapToMillsSliderValue, setSnapToMillsSliderValue] = useState(
    snapToMillsOptions.findIndex((option) => option.value === snapToMills)
  );

  const cloudSyncedDB = useCloudSyncedDB();
  const { processedChanges, totalChanges } = cloudSyncedDB;
  const { showModal } = useModal();
  const [
    defaultTimeslotDurationSliderValue,
    setDefaultTimeslotDurationSliderValue,
  ] = useState(
    defaultTimeslotDurationOptions.findIndex(
      (option) => option.value === defaultTimeslotDuration
    )
  );

  const [tempLocationHistoryEndpoint, setTempLocationHistoryEndpoint] =
    useState(locationHistoryEndpoint);

  const [tempCloudBackupEndpoint, setTempCloudBackupEndpoint] =
    useState(cloudBackupEndpoint);

  const getSnapToMillsSliderLabel = useCallback((number: number) => {
    return snapToMillsOptions[number].label;
  }, []);

  const getDefaultTimeslotDurationSliderLabel = useCallback(
    (number: number) => {
      return defaultTimeslotDurationOptions[number].label;
    },
    []
  );

  const errorMessage = (
    <>
      Invalid URL. Please check the endpoint and try again.
      <br />
      Endpoint was <strong>not</strong> saved.
    </>
  );

  const registerCloudBackup = async (endpoint: string): Promise<string> => {
    try {
      const response = await fetch(`${endpoint}/device`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        showSnackbar("Error registering cloud backup endpoint.", "error");
        return "";
      }

      const data = await response.json();
      return data.sync_key;
    } catch (error) {
      console.error("Error registering cloud backup endpoint:", error);
      return "";
    }
  };

  const testAndSaveLocationHistoryEndpoint = async () => {
    const locationHistory = await getLocationHistory(
      tempLocationHistoryEndpoint
    );
    if (locationHistory) {
      const success = await processAndInsertLocationHistory(
        cloudSyncedDB,
        locationHistory
      );
      if (success) {
        updateLocationHistoryEndpoint(
          tempLocationHistoryEndpoint,
          locationHistory
        );
        setLocationHistoryEndpoint(tempLocationHistoryEndpoint);
        showSnackbar(
          <>
            Endpoint verified and saved.
            <br />
            {success.insertedCount} locations inserted, {success.skippedCount}{" "}
            locations skipped.
          </>,
          "success"
        );
      } else {
        showSnackbar(errorMessage, "error");
      }
    } else {
      showSnackbar(errorMessage, "error");
    }
  };

  const disconnectLocationHistory = () => {
    setLocationHistoryEndpoint("");
    setTempLocationHistoryEndpoint("");
    showSnackbar("Location history disconnected.", "success");
  };

  const testAndSaveCloudBackupEndpoint = async () => {
    if (isValidUrl(tempCloudBackupEndpoint)) {
      setLastCloudChangeTimestamp(0);
      const syncKey = await registerCloudBackup(tempCloudBackupEndpoint);
      if (syncKey) {
        const result = await cloudSyncedDB.checkForChanges(
          tempCloudBackupEndpoint,
          syncKey
        );
        if (result === false) {
          showSnackbar("Cloud backup endpoint not valid.", "error");
        }
        if (result && result.new_changes > 0) {
          const confirmed = await showModal(
            "Server has data",
            MODAL_CLEAR_DB_BODY,
            {
              icon: <WarningIcon />,
              cancelText: "Do not import",
              okText: "Delete and import",
              colour: MATERIAL_COLOURS.red,
            }
          );
          if (confirmed) {
            await clearDatabase();
            setAppInitialised(true);
            // Download changes
            enableCloudSync(tempCloudBackupEndpoint, syncKey);
            showSnackbar("Downloading changes...", "info");
          }
        } else {
          const confirmed = await showModal(
            "Initialize cloud backup",
            MODAL_UPLOAD_DB_BODY,
            {
              icon: <WarningIcon />,
              cancelText: "Do not connect",
              okText: "Connect",
              colour: MATERIAL_COLOURS.green,
            }
          );
          if (confirmed) {
            enableCloudSync(tempCloudBackupEndpoint, syncKey);
            showSnackbar("Initializing cloud backup...", "info");
            await cloudSyncedDB.initialiseCloudBackup();
            showSnackbar(
              "Cloud backup endpoint verified and saved.",
              "success"
            );
          }
        }
      } else {
        showSnackbar("Cloud backup endpoint not valid.", "error");
      }
    } else {
      showSnackbar(errorMessage, "error");
    }
  };

  const disconnectCloudBackup = async () => {
    disableCloudSync();
    cloudSyncedDB.emptyCloudBackupChanges();
    setTempCloudBackupEndpoint("");
    showSnackbar("Cloud backup disconnected.", "success");
  };

  return (
    <Container>
      <Typography variant="h1">Settings Page</Typography>

      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1" width="100%" align="left">
          Snap to time
        </Typography>
        <Typography variant="body1" width="100%" align="right">
          {getSnapToMillsSliderLabel(snapToMillsSliderValue)}
        </Typography>
        <Slider
          sx={{ width: "100%" }}
          value={snapToMillsSliderValue}
          onChange={(_: Event, newValue: number | number[]) => {
            const sliderIndex = newValue as number;
            setSnapToMillsSliderValue(sliderIndex);
            setSnapToMills(snapToMillsOptions[sliderIndex].value);
          }}
          marks
          min={0}
          max={snapToMillsOptions.length - 1}
        />
      </Stack>
      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1" width="100%" align="left">
          Date Format
        </Typography>
        <Select
          value={dateFormat}
          onChange={(e) => setDateFormat(e.target.value)}
          sx={{ width: "100%" }}>
          {dateFormatOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1" width="100%" align="left">
          Default Timeslot Duration
        </Typography>
        <Typography variant="body1" width="100%" align="right">
          {getDefaultTimeslotDurationSliderLabel(
            defaultTimeslotDurationSliderValue
          )}
        </Typography>
        <Slider
          sx={{ width: "100%" }}
          value={defaultTimeslotDurationSliderValue}
          onChange={(_: Event, newValue: number | number[]) => {
            const sliderIndex = newValue as number;
            setDefaultTimeslotDurationSliderValue(sliderIndex);
            setDefaultTimeslotDuration(
              defaultTimeslotDurationOptions[sliderIndex].value
            );
          }}
          marks
          min={0}
          max={defaultTimeslotDurationOptions.length - 1}
        />
      </Stack>
      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1">Default Zoom</Typography>
        <Typography variant="body1" width="100%" align="right">
          {defaultZoom} px
        </Typography>
        <Slider
          sx={{ width: "100%" }}
          value={defaultZoom}
          onChange={(_: Event, newValue: number | number[]) => {
            const zoom = newValue as number;
            setDefaultZoom(zoom);
          }}
          marks
          step={20}
          min={10}
          max={200}
        />
      </Stack>

      <hr />

      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1">Location Tracking</Typography>
        <Switch
          checked={showLocation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setShowLocation(e.target.checked)
          }
        />
      </Stack>

      <hr />
      {showLocation &&
        (locationHistoryEndpoint ? (
          <>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="body1">Location History Endpoint</Typography>
              <TextField
                sx={{ width: "100%" }}
                placeholder="https://location-history.dumbertime.com/ABCDE"
                variant="standard"
                disabled
                value={locationHistoryEndpoint}
              />
              <Button
                variant="contained"
                color="error"
                onClick={disconnectLocationHistory}>
                Disconnect
              </Button>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 2 }}
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="body1">Auto Sync</Typography>
              {autoSyncLocationHistory ? (
                <Typography variant="body2" color="text.secondary">
                  Location history is synced every 5 minutes.
                </Typography>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  To sync location history, please click on the cloud sync
                  option in the hamburger menu.
                </Typography>
              )}
              <Switch
                checked={autoSyncLocationHistory}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAutoSyncLocationHistory(e.target.checked)
                }
              />
            </Stack>
          </>
        ) : (
          <>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="body1">Location History Endpoint</Typography>
              <TextField
                sx={{ width: "100%" }}
                placeholder="https://location-history.dumbertime.com/ABCDE"
                variant="standard"
                value={tempLocationHistoryEndpoint}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTempLocationHistoryEndpoint(e.target.value)
                }
              />
              <Button
                variant="contained"
                onClick={testAndSaveLocationHistoryEndpoint}>
                Test and Save
              </Button>
            </Stack>
          </>
        ))}

      <hr />

      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1">Cloud Backup Endpoint</Typography>

        {cloudSyncEnabled ? (
          <>
            <TextField
              sx={{ width: "100%" }}
              placeholder="https://cloud-backup.dumbertime.com/ABCDE"
              variant="standard"
              disabled
              value={tempCloudBackupEndpoint}
            />
            <Button
              variant="contained"
              color="error"
              onClick={disconnectCloudBackup}>
              Disconnect
            </Button>
          </>
        ) : (
          <>
            <TextField
              sx={{ width: "100%" }}
              placeholder="https://cloud-backup.dumbertime.com/ABCDE"
              variant="standard"
              value={tempCloudBackupEndpoint}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTempCloudBackupEndpoint(e.target.value)
              }
            />
            <Button
              variant="contained"
              onClick={testAndSaveCloudBackupEndpoint}>
              Test and Save
            </Button>
          </>
        )}
      </Stack>

      {cloudSyncEnabled && (
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2 }}
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="body1">Auto Sync</Typography>
          {autoSyncBackups ? (
            <Typography variant="body2" color="text.secondary">
              Backup is checked every 5 minutes. You can also manually backup by
              clicking on the hamburger menu and selecting the cloud sync
              option.
            </Typography>
          ) : (
            <Typography variant="body2" color="text.secondary">
              To backup, please click on the cloud sync option in the hamburger
              menu.
            </Typography>
          )}
          <Switch
            checked={autoSyncBackups}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAutoSyncBackups(e.target.checked)
            }
          />
        </Stack>
      )}

      {cloudSyncEnabled && developerMode && (
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: 2 }}
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="body1">Sync Batch Size</Typography>
          <TextField
            sx={{ width: "100%" }}
            value={syncBatchSize}
            type="number"
            slotProps={{
              htmlInput: {
                min: 2,
                max: 100000,
              },
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSyncBatchSize(parseInt(e.target.value))
            }
          />
        </Stack>
      )}

      {processedChanges !== totalChanges && totalChanges > 0 && (
        <>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Warning: Cloud backup initialisation in progress. Please do not
            leave this page until it's complete.
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(processedChanges / totalChanges) * 100}
            sx={{ mt: 1 }}
          />
          <Typography variant="body2" sx={{ mt: 1 }}>
            Progress: {processedChanges} / {totalChanges}
          </Typography>
        </>
      )}

      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1">Start Analytics Screen</Typography>
        <Select
          value={startAnalyticsScreen}
          onChange={(e) =>
            setStartAnalyticsScreen(e.target.value as StartAnalyticsScreen)
          }>
          {startAnalyticsScreenOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <hr />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body1">Developer Mode</Typography>
        <Switch
          checked={developerMode}
          onChange={(e) => {
            setDeveloperMode(e.target.checked);
          }}
        />
      </Stack>
      <hr />
      {developerMode && (
        <>
          <Typography variant="body1">Settings as JSON</Typography>
          <JSONDisplay
            style={{
              width: "100%",
              maxHeight: "500px",
              overflow: "auto",
            }}
            json={settingsAsJson}
          />
        </>
      )}
    </Container>
  );
};
