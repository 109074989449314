import styled from "@emotion/styled";
import {
  History as HistoryIcon,
  Redo,
  Today,
  Undo,
  ZoomIn,
} from "@mui/icons-material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

import TimeslotIcon from "@mui/icons-material/DnsOutlined";
import TimelineIcon from "@mui/icons-material/ViewListOutlined";
import {
  Button,
  Container,
  FormControlLabel,
  Input,
  Paper,
  Slider,
  Stack,
  Switch,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { addDays, format, isSameDay, parse } from "date-fns";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { KeyIcon } from "../../components/KeyIcon";
import { db, dbuuid, Place, Timeslot } from "../../database/db";
import { useActivityJoinCategory } from "../../hooks/useActivityJoinCategory";
import { useCloudSyncedDB } from "../../hooks/useCloudSyncedDb";
import useSessionBackedState from "../../hooks/useSessionBackedState";
import { useSettings } from "../../hooks/useSettings";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useUndoRedo } from "../../hooks/useUndoRedo";
import {
  applyTimeslotChanges,
  getTimeslotAdjustments,
} from "../../utils/conflict_management";
import { LocationTimeBlock } from "../../utils/location_history_processing";
import {
  dateStringToDayBoundsMills,
  getComputerTimezone,
} from "../../utils/time";
import { DatePickerCalendar } from "../shared/DatePickerCalendar";
import {
  NewTimeslotInfo,
  NewTimeslotParams,
  SuggestedTimeslotInfo,
} from "./components/AddTimeslotComponents";
import SelectedTimeslotInfo from "./components/SelectedTimeslotInfo";
import { TimelineView } from "./components/timeline/TimelineView";
import { TimeslotListView } from "./components/timeslots/TimeslotListView";

const ModeSwitch = styled(Switch)`
  & .MuiSwitch-switchBase {
    color: rgba(0, 0, 0, 0.8);

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.Mui-checked {
      color: rgba(0, 0, 0, 0.8);

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  & .MuiSwitch-track {
    background-color: rgba(0, 0, 0, 0.38);
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: rgba(0, 0, 0, 0.38);
  }
`;

const ONE_HOUR_MS = 60 * 60 * 1000;
export const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();

  const cloudSyncedDB = useCloudSyncedDB();

  const [date, setDate] = useState(() => {
    const params = new URLSearchParams(location.search);
    const dateParam = params.get("date");
    if (dateParam && /^\d{4}-\d{2}-\d{2}$/.test(dateParam)) {
      return dateParam;
    }
    return format(new Date(), "yyyy-MM-dd");
  });
  // Update URL when date changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (date !== format(new Date(), "yyyy-MM-dd")) {
      params.set("date", date);
    } else {
      params.delete("date");
    }
    navigate(`?${params.toString()}`, { replace: true });
  }, [date, navigate, location.search]);

  const {
    popUndoSteps,
    pushUndoSteps,
    pushRedoSteps,
    popRedoSteps,
    totalUndoSteps,
    totalRedoSteps,
  } = useUndoRedo();
  const { defaultZoom, dateFormat } = useSettings();
  const [pxPerHr, setPxPerHr] = useSessionBackedState(
    defaultZoom,
    "home-pxPerHr"
  );
  const pxPerMs = useMemo(() => {
    return pxPerHr / (60 * 60 * 1000);
  }, [pxPerHr]);

  const nextAllowedZoom = useRef(0);

  const { activityCategoryMap } = useActivityJoinCategory();

  const [displayMode, setDisplayMode] = useState<"timeline" | "timeslot">(
    () => {
      const params = new URLSearchParams(location.search);
      return params.get("scheduleView") === "true" ? "timeslot" : "timeline";
    }
  );

  const currentScrollMs = useRef(0);

  // Keep the existing effect for updating the URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (date !== format(new Date(), "yyyy-MM-dd")) {
      params.set("date", date);
    } else {
      params.delete("date");
    }
    if (displayMode === "timeslot") {
      params.set("scheduleView", "true");
    } else {
      params.delete("scheduleView");
    }
    navigate(`?${params.toString()}`, { replace: true });
  }, [date, displayMode, navigate, location.search]);

  const toggleDisplayMode = useCallback(() => {
    setDisplayMode((prev) => (prev === "timeline" ? "timeslot" : "timeline"));
  }, [setDisplayMode]);

  const [showLocationHistory, setShowLocationHistory] = useState(true);

  const [editingTimeslot, setEditingTimeslot] = useState<string | null>(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
    null
  );

  const [newTimeslotParams, setNewTimeslotParams] =
    useState<NewTimeslotParams | null>(null);

  const [currentTimeMills, setCurrentTimeMills] = useState<number>(0);

  const [showCalendar, setShowCalendar] = useState(false);

  const dayBounds = useMemo(() => {
    return dateStringToDayBoundsMills(date, getComputerTimezone());
  }, [date]);

  const timeslots = useLiveQuery(async () => {
    if (!dayBounds) return [];
    const timeslots = await db.timeslots
      .where("startTimestampMills")
      .between(dayBounds[0], dayBounds[1])
      .or("endTimestampMills")
      .between(dayBounds[0], dayBounds[1])
      .toArray();

    // Timeslots that exceed the day bounds should be clamped
    return timeslots
      .map((timeslot) => {
        return {
          ...timeslot,
          startTimestampMills: Math.max(
            dayBounds[0],
            Math.min(dayBounds[1], timeslot.startTimestampMills)
          ),
          endTimestampMills: Math.max(
            dayBounds[0],
            Math.min(dayBounds[1], timeslot.endTimestampMills)
          ),
        };
      })
      .filter((timeslot) => {
        // Remove timeslots that are <= 0 milliseconds long
        return timeslot.startTimestampMills < timeslot.endTimestampMills;
      });
  }, [dayBounds]);

  // Get the location history for the day
  const locationHistory = useLiveQuery(() => {
    if (!dayBounds) return [];

    return db.locationHistories
      .where("timestampMills")
      .between(dayBounds[0] - ONE_HOUR_MS, dayBounds[1] + ONE_HOUR_MS)
      .toArray();
  }, [dayBounds]);

  const selectedTimeslotLotLon = useMemo(() => {
    if (!selectedTimeslot) return null;
    if (!locationHistory) return null;
    // Find the latlon of the timeslot based on the location history
    const closestLocation = locationHistory.find((location) => {
      return (
        location.timestampMills >= selectedTimeslot.startTimestampMills &&
        location.timestampMills <= selectedTimeslot.endTimestampMills
      );
    });
    if (!closestLocation) return null;
    return {
      latitude: closestLocation.latitude,
      longitude: closestLocation.longitude,
    };
  }, [selectedTimeslot, locationHistory]);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const changeTimeslotActivityAndTime = useCallback(
    async (
      timeslot: Timeslot,
      activityId: string,
      startMs: number,
      endMs: number
    ) => {
      if (!activityId) return;
      const updatedTimeslot = {
        ...timeslot,
        activityId: activityId,
        startTimestampMills: startMs,
        endTimestampMills: endMs,
      };
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [
          {
            action: "update",
            timeslot: updatedTimeslot,
          },
        ],
        true
      );
      pushUndoSteps(undoSteps, true);
      setEditingTimeslot(null);
    },
    [cloudSyncedDB, pushUndoSteps]
  );

  const changeTimeslotPlace = useCallback(
    async (timeslot: Timeslot, placeId: string | null) => {
      const updatedTimeslot = {
        ...timeslot,
        placeId: placeId || undefined,
      };
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [
          {
            action: "update",
            timeslot: updatedTimeslot,
          },
        ],
        true
      );
      pushUndoSteps(undoSteps, true);
    },
    [cloudSyncedDB, pushUndoSteps]
  );
  const addTimeslot = useCallback(
    async (timeslot: {
      startMs: number;
      endMs: number;
      activityId: string;
    }) => {
      const { startMs, endMs, activityId } = timeslot;

      const newTimeslot = {
        id: dbuuid(),
        startTimestampMills: startMs,
        endTimestampMills: endMs,
        activityId,
        timezone: getComputerTimezone(),
      };

      const timeslotChanges = await getTimeslotAdjustments(timeslots || [], {
        id: dbuuid(),
        startTimestampMills: startMs,
        endTimestampMills: endMs,
        activityId,
        timezone: getComputerTimezone(),
      });

      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges, { action: "add", timeslot: newTimeslot }],
        true
      );

      pushUndoSteps(undoSteps, true);
    },
    [timeslots, pushUndoSteps, cloudSyncedDB]
  );

  const deleteTimeslot = useCallback(
    async (timeslotId: string) => {
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [{ action: "delete", timeslot: { id: timeslotId } }],
        true
      );
      pushUndoSteps(undoSteps, true);
    },
    [cloudSyncedDB, pushUndoSteps]
  );

  const deselectAll = useCallback(() => {
    if (selectedTimeslot) setSelectedTimeslot(null);
    if (editingTimeslot) setEditingTimeslot(null);
    if (newTimeslotParams) setNewTimeslotParams(null);
  }, [selectedTimeslot, editingTimeslot, newTimeslotParams]);

  const openAddTimeslot = useCallback(
    (ms: number, length: number = 60 * 60 * 1000) => {
      // setShowAddTimeslot(true);
      // setLastSelectedHour(hour);
      deselectAll();
      setNewTimeslotParams({
        startMs: ms,
        endMs: ms + length,
        activityId: "",
      });
    },
    [deselectAll]
  );

  const applyPlaceToTimeslots = useCallback(
    async (locationTimeBlock: LocationTimeBlock, place: Place) => {
      if (!timeslots) return;

      // Find all timeslots that overlap with this location block
      const overlappingTimeslots = timeslots.filter((timeslot) => {
        return (
          timeslot.startTimestampMills <= locationTimeBlock.endMills &&
          timeslot.endTimestampMills >= locationTimeBlock.startMills
        );
      });

      if (overlappingTimeslots.length === 0) return;

      // Update each overlapping timeslot with the new place
      const timeslotChanges = overlappingTimeslots.map((timeslot) => ({
        action: "update" as const,
        timeslot: {
          ...timeslot,
          placeId: place.id,
        },
      }));

      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        timeslotChanges,
        true
      );
      pushUndoSteps(undoSteps, true);
    },
    [timeslots, cloudSyncedDB, pushUndoSteps]
  );

  const isToday = useMemo(() => {
    return isSameDay(new Date(), parse(date, "yyyy-MM-dd", new Date()));
  }, [date]);

  const performUndo = useCallback(async () => {
    const undoSteps = popUndoSteps();
    if (!undoSteps) return;
    const redoSteps = await applyTimeslotChanges(
      cloudSyncedDB,
      undoSteps,
      true
    );
    pushRedoSteps(redoSteps);
  }, [popUndoSteps, pushRedoSteps, cloudSyncedDB]);

  const performRedo = useCallback(async () => {
    const redoSteps = popRedoSteps();
    if (!redoSteps) return;
    const undoSteps = await applyTimeslotChanges(
      cloudSyncedDB,
      redoSteps,
      true
    );
    pushUndoSteps(undoSteps, false);
  }, [popRedoSteps, pushUndoSteps, cloudSyncedDB]);

  // Update the date change handlers
  const goToPreviousDay = useCallback(() => {
    const currentDateObj = parse(date, "yyyy-MM-dd", new Date());
    const oneDayBefore = addDays(currentDateObj, -1);
    setDate(format(oneDayBefore, "yyyy-MM-dd"));
    deselectAll();
  }, [date, setDate, deselectAll]);

  const goToToday = useCallback(() => {
    setDate(format(new Date(), "yyyy-MM-dd"));
    setShowCalendar(false);
    deselectAll();
  }, [setDate, deselectAll]);

  const goToNextDay = useCallback(() => {
    const currentDateObj = parse(date, "yyyy-MM-dd", new Date());
    const oneDayAfter = addDays(currentDateObj, 1);
    setDate(format(oneDayAfter, "yyyy-MM-dd"));
    deselectAll();
  }, [date, setDate, deselectAll]);

  const cancelAddingTimeslot = useCallback(() => {
    setNewTimeslotParams(null);
  }, [setNewTimeslotParams]);

  const handleAddTimeslot = useCallback(
    (timeslot: NewTimeslotParams) => {
      if (timeslot.activityId === "") {
        return;
      }
      addTimeslot(timeslot);
      setNewTimeslotParams(null);
    },
    [setNewTimeslotParams, addTimeslot]
  );

  const handleUpdateActivity = useCallback(
    (activityId: string) => {
      if (!newTimeslotParams) return;
      if (newTimeslotParams.activityId !== activityId) {
        setNewTimeslotParams({ ...newTimeslotParams, activityId });
      }
    },
    [newTimeslotParams, setNewTimeslotParams]
  );

  const scrollToCurrentTime = useCallback(() => {
    const scrollContainer = document.querySelector(".scroll-container");
    if (!scrollContainer) return;
    const currentTimeElement = document.querySelector(".current-time-line");
    if (!currentTimeElement) return;
    const topBar = document.querySelector(".top-bar");
    if (!topBar) return;

    const containerRect = scrollContainer.getBoundingClientRect();
    const timelineRect = currentTimeElement.getBoundingClientRect();
    const relativeTop =
      timelineRect.top - containerRect.top + scrollContainer.scrollTop;

    scrollContainer.scrollTo({
      top: relativeTop - topBar.clientHeight - 20,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const scrollContainer = document.querySelector(
      ".scroll-container"
    ) as HTMLElement;
    if (!scrollContainer) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (newTimeslotParams) {
        // Handle adding timeslot keyboard shortcuts
        if (event.key === "Escape") {
          cancelAddingTimeslot();
        }
        if (event.key === "Enter") {
          handleAddTimeslot(newTimeslotParams);
        }
        return;
      }
      if (event.key === "Escape") {
        if (showCalendar) setShowCalendar(false);
        else if (editingTimeslot) setEditingTimeslot(null);
        else if (selectedTimeslot) setSelectedTimeslot(null);
      } else if (event.key === "ArrowUp") {
        // Jump to the previous timeslot
        if (selectedTimeslot && timeslots) {
          const currentIndex = timeslots.findIndex(
            (t) => t.id === selectedTimeslot.id
          );
          if (currentIndex > 0) {
            setSelectedTimeslot(timeslots[currentIndex - 1]);
          }
        }
      } else if (event.key === "ArrowDown") {
        // Jump to the next timeslot
        if (selectedTimeslot && timeslots) {
          const currentIndex = timeslots.findIndex(
            (t) => t.id === selectedTimeslot.id
          );
          if (currentIndex < timeslots.length - 1) {
            setSelectedTimeslot(timeslots[currentIndex + 1]);
          }
        }
      } else if (event.key === "e") {
        // Edit the selected timeslot
        if (selectedTimeslot) {
          setEditingTimeslot(selectedTimeslot.id);
        }
      } else if (event.key === "t") {
        if (!isToday) {
          goToToday();
        } else {
          scrollToCurrentTime();
          showSnackbar("Already on today's date", "info", 2000);
        }
      } else if (event.key === "1" || event.key === "d") {
        toggleDisplayMode();
      } else if (event.key === "ArrowLeft") {
        // Go to the previous day
        goToPreviousDay();
      } else if (event.key === "ArrowRight") {
        // Go to the next day
        goToNextDay();
      } else if (event.key === "Delete" || event.key === "Backspace") {
        // Delete the selected timeslot
        if (selectedTimeslot) {
          deleteTimeslot(selectedTimeslot.id);
          deselectAll();
        }
      } else if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === "z"
      ) {
        performRedo();
      } else if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        performUndo();
      }
    };

    const handleScroll = (event: WheelEvent) => {
      if (event.metaKey || event.ctrlKey) {
        event.preventDefault();
        const now = new Date().getTime();
        if (now > nextAllowedZoom.current) {
          // 100ms cooldown to prevent janky zooming on mac trackpads
          nextAllowedZoom.current = now + 100;
          if (event.deltaY > 0) {
            setPxPerHr(Math.min(pxPerHr - 20, nextAllowedZoom.current));
          } else {
            setPxPerHr(Math.max(pxPerHr + 20, 10));
          }
        }
        return;
      }
      const target = event.currentTarget as HTMLElement;
      const scrollTop = target.scrollTop;

      const currentMs = Math.max(scrollTop - 50, 0) / pxPerMs;
      currentScrollMs.current = currentMs;
    };

    window.addEventListener("keydown", handleKeyDown);
    scrollContainer.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      scrollContainer.removeEventListener("wheel", handleScroll);
    };
  }, [
    selectedTimeslot,
    editingTimeslot,
    timeslots,
    deleteTimeslot,
    deselectAll,
    showCalendar,
    goToPreviousDay,
    goToNextDay,
    performRedo,
    performUndo,
    isToday,
    goToToday,
    showSnackbar,
    newTimeslotParams,
    cancelAddingTimeslot,
    handleAddTimeslot,
    scrollToCurrentTime,
    toggleDisplayMode,
    dayBounds,
    pxPerMs,
    setPxPerHr,
    pxPerHr,
  ]);

  useEffect(() => {
    const saveScrollMs = currentScrollMs.current;
    const newScrollTop = saveScrollMs * pxPerMs + 50;
    const scrollContainer = document.querySelector(
      ".scroll-container"
    ) as HTMLElement;
    if (!scrollContainer) return;
    scrollContainer.scrollTo({
      top: newScrollTop,
      behavior: "instant",
    });
  }, [pxPerMs]);

  useEffect(() => {
    const setCurrentTime = () => {
      // Round to the nearest minute
      const nowMills = new Date().getTime();
      const nowRounded = Math.round(nowMills / 60000) * 60000;
      if (
        dayBounds &&
        (nowRounded < dayBounds[0] || nowRounded > dayBounds[1])
      ) {
        setCurrentTimeMills(0);
        return;
      }
      setCurrentTimeMills(nowRounded);
    };
    setCurrentTime();
    const timer = setInterval(() => {
      setCurrentTime();
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [dayBounds]);

  return (
    <Container
      sx={{
        userSelect: "none",
      }}>
      <Paper
        className="top-bar"
        sx={{
          padding: 2,
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 2000,
        }}>
        <Stack
          direction="column"
          gap={2}
          width="100%"
          justifyContent="space-around">
          {/* Date selector */}
          <Stack
            direction="row"
            gap={3}
            width="100%"
            justifyContent="space-around">
            <Stack direction="row" width="100%" gap={0}>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Go to previous day
                    <KeyIcon keyName="ArrowLeft" />
                  </Stack>
                }>
                <Button onClick={goToPreviousDay}>&lt;</Button>
              </Tooltip>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Go to today
                    <KeyIcon keyName="T" />
                  </Stack>
                }>
                <Button onClick={goToToday}>
                  <Today />
                </Button>
              </Tooltip>
              <Input
                sx={{
                  width: "100%",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                type="text"
                value={format(
                  parse(date, "yyyy-MM-dd", new Date()),
                  dateFormat
                )}
                onClick={() => setShowCalendar((c) => !c)}
                readOnly
              />
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Go to next day
                    <KeyIcon keyName="ArrowRight" />
                  </Stack>
                }>
                <Button onClick={goToNextDay}>&gt;</Button>
              </Tooltip>
            </Stack>
            <Stack direction="row" gap={0}>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Undo
                    <Stack direction="row" gap={0}>
                      <KeyIcon keyName="Control" />
                      <KeyIcon keyName="Z" />
                    </Stack>
                  </Stack>
                }>
                <span>
                  <Button disabled={totalUndoSteps === 0} onClick={performUndo}>
                    <Undo sx={{ marginRight: 1 }} /> {totalUndoSteps}
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  <Stack direction="row" gap={1}>
                    Redo
                    <Stack direction="row" gap={0}>
                      <KeyIcon keyName="Control" />
                      <KeyIcon keyName="Shift" />
                      <KeyIcon keyName="Z" />
                    </Stack>
                  </Stack>
                }>
                <span>
                  <Button disabled={totalRedoSteps === 0} onClick={performRedo}>
                    <Redo sx={{ marginRight: 1 }} /> {totalRedoSteps}
                  </Button>
                </span>
              </Tooltip>
            </Stack>
            <Tooltip
              title={
                <Stack direction="row" gap={1}>
                  Toggle display mode
                  <KeyIcon keyName="D" />
                </Stack>
              }>
              <Stack
                direction="row"
                alignItems="center"
                gap={0}
                onClick={(_) => {
                  toggleDisplayMode();
                }}
                sx={{ cursor: "pointer" }}>
                <TimelineIcon />
                <ModeSwitch
                  checked={displayMode === "timeslot"}
                  onChange={(e) =>
                    setDisplayMode(e.target.checked ? "timeslot" : "timeline")
                  }
                />
                <TimeslotIcon />
              </Stack>
            </Tooltip>
          </Stack>
          {showCalendar && (
            <Stack direction="row" gap={2} justifyContent="center">
              <DatePickerCalendar
                $isSmallScreen={!isMediumScreen}
                showDoubleView={isMediumScreen}
                next2Label={null}
                prev2Label={null}
                onChange={(value) => {
                  if (value instanceof Date) {
                    setDate(format(value, "yyyy-MM-dd"));
                    setShowCalendar(false);
                  }
                }}
                value={parse(date, "yyyy-MM-dd", new Date())}
              />
            </Stack>
          )}
          {/* Scale selector */}
          {displayMode === "timeline" && (
            <Stack direction="row" gap={3} alignItems="center">
              <ZoomIn color="primary" />
              <Slider
                marks
                size="medium"
                value={pxPerHr}
                onChange={(_, newValue) => {
                  setPxPerHr(newValue as number);
                }}
                min={10}
                max={400}
                step={10}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value}px`}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showLocationHistory}
                    onChange={(e) => setShowLocationHistory(e.target.checked)}
                  />
                }
                label={
                  <Stack direction="row" gap={0}>
                    <GpsFixedIcon />
                    <HistoryIcon />
                  </Stack>
                }
              />
            </Stack>
          )}
        </Stack>
      </Paper>

      {/* Timeslots */}
      {displayMode === "timeline" ? (
        <TimelineView
          dayBounds={dayBounds}
          pxPerHr={pxPerHr}
          pxPerMs={pxPerMs}
          timeslots={timeslots}
          locationHistory={locationHistory}
          activityCategoryMap={activityCategoryMap}
          showLocationHistory={showLocationHistory}
          currentTimeMills={currentTimeMills}
          editingTimeslot={editingTimeslot}
          selectedTimeslot={selectedTimeslot}
          openAddTimeslot={openAddTimeslot}
          applyPlaceToTimeslots={applyPlaceToTimeslots}
          newTimeslotParams={newTimeslotParams}
          setEditingTimeslot={setEditingTimeslot}
          setNewTimeslotParams={setNewTimeslotParams}
          setSelectedTimeslot={setSelectedTimeslot}
          deselectAll={deselectAll}
          setPxPerHr={setPxPerHr}
        />
      ) : (
        dayBounds && (
          <TimeslotListView
            dayBounds={dayBounds}
            timeslots={timeslots || []}
            activityCategoryMap={activityCategoryMap}
            selectedTimeslot={selectedTimeslot}
            editingTimeslot={editingTimeslot}
            onTimeslotClick={(timeslot) => {
              setSelectedTimeslot(timeslot);
              setEditingTimeslot(null);
              setNewTimeslotParams(null);
            }}
            setEditingTimeslot={setEditingTimeslot}
            onBlankClick={(startMs, endMs) => {
              openAddTimeslot(startMs, endMs - startMs);
            }}
          />
        )
      )}

      {newTimeslotParams !== null ? (
        <NewTimeslotInfo
          newTimeslotParams={newTimeslotParams}
          cancel={cancelAddingTimeslot}
          addTimeslot={handleAddTimeslot}
          updateActivity={handleUpdateActivity}
        />
      ) : selectedTimeslot ? (
        <SelectedTimeslotInfo
          selectedTimeslot={selectedTimeslot}
          selectedTimeslotLotLon={selectedTimeslotLotLon}
          editingTimeslot={editingTimeslot}
          setEditingTimeslot={(editingTimeslot) => {
            setEditingTimeslot(editingTimeslot);
          }}
          onCancelEdit={() => {
            setEditingTimeslot(null);
          }}
          changeTimeslotActivity={(activityId, startMs, endMs) => {
            if (!selectedTimeslot) return;
            changeTimeslotActivityAndTime(
              selectedTimeslot,
              activityId,
              startMs,
              endMs
            );
          }}
          changeTimeslotPlace={(placeId) => {
            if (!selectedTimeslot) return;
            changeTimeslotPlace(selectedTimeslot, placeId);
          }}
        />
      ) : isToday ? (
        <SuggestedTimeslotInfo
          setAddingActivity={(activityId) => {
            setNewTimeslotParams({
              startMs: currentTimeMills,
              endMs: currentTimeMills + 60 * 60 * 1000,
              activityId,
            });
            setTimeout(() => {
              // Add scroll behavior here
              scrollToCurrentTime();
            }, 100);
          }}
        />
      ) : null}
    </Container>
  );
};
