import styled from "@emotion/styled";
import { format } from "date-fns";
import React from "react";
import { Timeslot } from "../../../../database/db";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { Box, Stack, Typography } from "@mui/material";
import { QuestionMark } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ICONS } from "../../../../const/icons";
import { heightFromMinutes, timeDisplay } from "../../../../utils/time";
import { OUTLINE_COLORS } from "../../../../const/colours";

const TimeslotContainer = styled(Box)<{
  isselected: string;
  isediting: string;
  isconflicting: string;
}>`
  padding: 4px 12px;
  margin: 0;

  border: ${(props) =>
    props.isconflicting === "true"
      ? `2px solid ${OUTLINE_COLORS.conflicting}`
      : props.isediting === "true"
      ? `2px solid ${OUTLINE_COLORS.editing}`
      : props.isselected === "true"
      ? `1px solid ${OUTLINE_COLORS.selected}`
      : `1px solid #e0e0e0`};
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

export const TimeDisplay = styled(Typography)`
  font-size: 14px;
  margin-top: -16px;
  background-color: #ffffffcc;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
`;

interface TimeslotComponentProps {
  timeslot: Timeslot;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  isSelected: boolean;
  isEditing: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
}

export const TimeslotComponent: React.FC<TimeslotComponentProps> = ({
  timeslot,
  activityCategoryMap,
  isSelected,
  isEditing,
  onClick,
  onDoubleClick,
}) => {
  const activity = activityCategoryMap[timeslot.activityId];
  const Icon = activity ? ICONS[activity.icon] : QuestionMark;
  const colour = activity ? activity.colour : "#999999";
  const name = activity ? activity.name : "Unknown Activity";
  const startTime = format(new Date(timeslot.startTimestampMills), "HH:mm");
  const minutes =
    (timeslot.endTimestampMills - timeslot.startTimestampMills) / 1000 / 60;

  const verticalHeight = heightFromMinutes(minutes);

  return (
    <TimeslotContainer
      isselected={isSelected.toString()}
      isediting={isEditing.toString()}
      isconflicting={false.toString()}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      sx={{
        backgroundColor: isSelected ? `${colour}CC` : `${colour}33`,
        "&:hover": {
          backgroundColor: isSelected ? `${colour}CC` : `${colour}55`,
        },
        height: verticalHeight + "px",
      }}>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <Stack direction="column" alignItems="center">
          <TimeDisplay>{startTime}</TimeDisplay>
        </Stack>

        <Stack
          sx={{ flexGrow: 1 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          height="100%"
          gap={2}>
          <Icon />
          <Typography variant="subtitle1" fontWeight={500}>
            {name}
          </Typography>
        </Stack>
        {timeslot.placeId && <LocationOnIcon />}

        <Typography variant="body2" color="text.secondary">
          {timeDisplay(minutes)}
        </Typography>
      </Stack>
    </TimeslotContainer>
  );
};
