import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { ICONS } from "../../../const/icons";
import { Place } from "../../../database/db";
import { LocationTimeBlock } from "../../../utils/location_history_processing";
import { LOCATION_COLORS } from "../../../const/colours";
interface LocationTimeBlockProps {
  block: LocationTimeBlock;
  dayStartMills: number;
  pxPerMs: number;
  place?: Place;
  onClick: () => void;
  onDoubleClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const LocationTimeBlockComponent: React.FC<LocationTimeBlockProps> = ({
  block,
  dayStartMills,
  pxPerMs,
  onClick,
  onDoubleClick,
  onMouseEnter,
  onMouseLeave,
  place,
}) => {
  const top = (block.startMills - dayStartMills) * pxPerMs;
  const accurateHeight = (block.accurateMills - block.startMills) * pxPerMs;
  const totalHeight = (block.endMills - block.startMills) * pxPerMs;

  const Icon = useMemo(() => {
    if (!place) {
      return null;
    }
    return ICONS[place.icon];
  }, [place]);

  // Convert locationId to a numerical value by summing the character codes.
  const locationHash = Array.from(block.locationId).reduce(
    (sum, char) => sum + char.charCodeAt(0),
    0
  );
  const color = LOCATION_COLORS[locationHash % LOCATION_COLORS.length];

  if (block.accurateMills === block.endMills) {
    return (
      <Box
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
        sx={{
          position: "absolute",
          top: `${top}px`,
          left: 0,
          right: "10px",
          height: `${accurateHeight}px`,
          backgroundColor: `#CCCCCC88`,
          borderTop: `1px solid #888`,
          borderLeft: `3px solid ${color}`,
          cursor: "pointer",
        }}>
        {Icon && (
          <Icon
            sx={{
              marginTop: "3px",
              height: "15px",
              width: "15px",
            }}
          />
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: `${top}px`,
        left: 0,
        right: "10px",
        height: `${totalHeight}px`,
        cursor: "pointer",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}>
      <Box
        sx={{
          width: "100%",
          height: `${accurateHeight}px`,
          backgroundColor: `#CCCCCC88`,
          borderTop: `1px solid #888`,
          borderLeft: `3px solid ${color}`,
        }}>
        {Icon && (
          <Icon
            sx={{
              marginTop: "3px",
              height: "15px",
              width: "15px",
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: `${totalHeight - accurateHeight}px`,
          background: `linear-gradient(to bottom, 
            #CCCCCC88 0%,
            #CCCCCC32 30%,
            #CCCCCC22 100%)`,
          borderLeft: `3px solid ${color}`,
        }}
      />
    </Box>
  );
};

export default LocationTimeBlockComponent;
