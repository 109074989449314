import React from "react";
import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import { timeDisplay } from "../../../../utils/time";
import {
  MID_TIMESLOT_HEIGHT,
  SHORT_TIMESLOT_HEIGHT,
} from "./TimeslotComponent";

const BlankTimeslotRowStyled = styled(Box)`
  position: absolute;
  left: 50px;
  width: calc(100% - 120px);
  z-index: 1;
  box-sizing: border-box;
  opacity: 1;
  background-color: #eeeeee88;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  cursor: pointer;
`;

interface BlankTimeslotRowProps {
  startTimestampMills: number;
  endTimestampMills: number;
  dayBounds: number[];
  pxPerMs: number;
  onMouseEnter: () => void;
  onClick: () => void;
  onDoubleClick: () => void;
}

const BlankTimeslotComponent: React.FC<BlankTimeslotRowProps> = ({
  startTimestampMills,
  endTimestampMills,
  dayBounds,
  pxPerMs,
  onMouseEnter,
  onClick,
  onDoubleClick,
}) => {
  const top = (startTimestampMills - dayBounds[0]) * pxPerMs;
  const height = (endTimestampMills - startTimestampMills) * pxPerMs;
  const minutes = (endTimestampMills - startTimestampMills) / 1000 / 60;

  return (
    <BlankTimeslotRowStyled
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      sx={{
        top: `${top}px`,
        height: `${height}px`,
      }}>
      {height >= MID_TIMESLOT_HEIGHT ? (
        <Stack direction="row" padding={1} gap={1} justifyContent="flex-end">
          <Typography color="#888888" fontStyle="italic">
            {timeDisplay(minutes)}
          </Typography>
        </Stack>
      ) : height >= SHORT_TIMESLOT_HEIGHT ? (
        <Stack direction="row" paddingX={1} gap={1} justifyContent="flex-end">
          <Typography
            color="#888888"
            fontStyle="italic"
            sx={{ fontSize: "12px" }}>
            {timeDisplay(minutes)}
          </Typography>
        </Stack>
      ) : null}
    </BlankTimeslotRowStyled>
  );
};

export default BlankTimeslotComponent;
