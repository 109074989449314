import { TZDate } from "@date-fns/tz";
import styled from "@emotion/styled";
import { ArrowDownward, ArrowUpward, DragHandle } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PRIMARY_COLOUR } from "../../../../const/colours";
import { LocationHistory, Place, Timeslot } from "../../../../database/db";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { useCloudSyncedDB } from "../../../../hooks/useCloudSyncedDb";
import { useSettings } from "../../../../hooks/useSettings";
import { useUndoRedo } from "../../../../hooks/useUndoRedo";
import {
  applyTimeslotChanges,
  getConflictingTimeslots,
  getTimeslotAdjustments,
} from "../../../../utils/conflict_management";
import {
  createTimeBlocksFromLocationHistory,
  LocationTimeBlock,
} from "../../../../utils/location_history_processing";
import {
  getComputerTimezone,
  hourLabelBackgroundColor,
} from "../../../../utils/time";
import {
  generateTimeslotBoundaries,
  getBlankTimeRanges,
} from "../../../../utils/timeslot";
import {
  NewTimeslotDraggableIndicator,
  NewTimeslotParams,
} from "../AddTimeslotComponents";
import { LocationHistoryColumn } from "../LocationHistoryColumn";
import BlankTimeslotComponent from "./BlankTimeslotComponent";
import { CurrentTimeLine } from "./CurrentTimeLine";
import DraggingTimeslotComponent from "./DraggingTimeslotComponent";
import TimeslotComponent from "./TimeslotComponent";

type BlankTimeslot = {
  startTimestampMills: number;
  endTimestampMills: number;
};

type AddButtonPosition = {
  addMs: number;
  addPx: number;
  addHeightPx: number;
  addMsLength: number;
};

const Hour = styled.div<{ height: number }>`
  height: ${(props) => props.height - 1}px;
  position: relative;
  background-color: white;
  flex-grow: 1;

  outline: 1px solid #00000022;
`;

const HourLabel = styled.div<{ backgroundColor: string }>`
  font-size: 13px;
  width: 50px;
  text-align: left;
  opacity: 1;
  color: #000;
  background-color: ${(props) => props.backgroundColor};
  outline: 1px solid ${(props) => props.backgroundColor};
  display: flex;
  align-items: start;
  justify-content: center;
  div {
    font-size: 12px;
    color: #666;
    font-weight: bold;
    margin-top: -9px;
    opacity: 0.5;
    filter: drop-shadow(1px 1px 1px #fff) drop-shadow(-1px -1px 1px #fff);
    transition: opacity 0.1s linear;
  }
  &:hover {
    div {
      opacity: 1;
    }
  }
`;

const AddButton = styled(Button)<{ height: number }>`
  position: absolute;
  width: 130px;
  min-width: 0;
  right: 0px;
  font-size: 20px;
  background: #ffffffaa;
  height: ${(props) => props.height}px;
  z-index: 400;
  cursor: pointer;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  .timeLabel {
    position: absolute;
    left: -50px;
    color: #666;
    font-size: 14px;
    background-color: #ffffffaa;
    padding: 2px 4px;
    border-radius: 2px;

    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

    opacity: 0;
    transition: opacity 0.1s linear;

    &.top {
      top: 0px;
    }
    &.bottom {
      bottom: 0px;
    }
  }

  &:hover {
    .timeLabel {
      opacity: 1;
    }
  }
`;

const DragButton = styled(Button)`
  position: absolute;
  background-color: #ffffffaa;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 20px;
  padding: 0;
  margin: 0;
  left: 50px;
  min-width: 0;
  z-index: 200;
  cursor: grab;
  opacity: 0.5;
  transition: opacity 0.1s linear;
  border: 1px solid ${PRIMARY_COLOUR}00;
  .handleIcon {
    font-size: 15px;
  }

  &:hover {
    border: 1px solid ${PRIMARY_COLOUR}22;
    opacity: 1;
  }
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

const ScaleButton = styled(DragButton)`
  cursor: ns-resize;
  height: 10px;
  width: calc(100% - 250px);
`;

const MergeButton = styled(DragButton)`
  height: 20px;
  width: 80px;
  right: 75px;
  left: initial;
  cursor: pointer;
`;

const TimeDisplay = styled.div<{ top: number }>`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: 5px;
  width: 40px;
  height: 25px;
  padding: 2px;
  background-color: ${(props) =>
    props.theme === "hover" ? "#EEEEEE" : "white"};
  text-align: left;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 13px;
`;

const TimeslotColumn = styled(Stack)`
  position: relative;
`;

type TimelineDisplayProps = {
  dayBounds: [number, number] | null;
  pxPerHr: number;
  pxPerMs: number;
  timeslots: Timeslot[] | undefined;
  locationHistory: LocationHistory[] | undefined;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  showLocationHistory: boolean;
  currentTimeMills: number;
  editingTimeslot: string | null;
  newTimeslotParams: NewTimeslotParams | null;
  selectedTimeslot: Timeslot | null;

  // Handlers that need to stay in parent
  setPxPerHr: (pxPerHr: number) => void;
  openAddTimeslot: (ms: number, length?: number) => void;
  applyPlaceToTimeslots: (
    locationTimeBlock: LocationTimeBlock,
    place: Place
  ) => void;
  setEditingTimeslot: (timeslotId: string | null) => void;
  setNewTimeslotParams: (params: NewTimeslotParams | null) => void;
  setSelectedTimeslot: (timeslot: Timeslot | null) => void;
  deselectAll: () => void;
};

export const TimelineView = ({
  dayBounds,
  pxPerHr,
  pxPerMs,
  timeslots,
  locationHistory,
  activityCategoryMap,
  showLocationHistory,
  currentTimeMills,
  editingTimeslot,
  newTimeslotParams,
  selectedTimeslot,
  openAddTimeslot,
  applyPlaceToTimeslots,
  setEditingTimeslot,
  setNewTimeslotParams,
  setSelectedTimeslot,
  deselectAll,
}: TimelineDisplayProps) => {
  const { snapToMills, defaultTimeslotDuration } = useSettings();
  const { pushUndoSteps } = useUndoRedo();
  const cloudSyncedDB = useCloudSyncedDB();

  // create a list of hours from 0 to 23
  const hours = useState([...Array(24).keys()])[0];

  const [conflictingTimeslots, setConflictingTimeslots] = useState<string[]>(
    []
  );

  const [dragTopMills, setDragTopMills] = useState<number>(0);
  const [dragBottomMills, setDragBottomMills] = useState<number>(0);

  // Local state that doesn't need to be in parent
  const [hoveredTimeslot, setHoveredTimeslot] = useState<Timeslot | null>(null);
  const [draggingTimeslot, setDraggingTimeslot] = useState<Timeslot | null>(
    null
  );
  const [buttonDragging, setButtonDragging] = useState(false);
  const [dragStartY, setDragStartY] = useState(0);
  const [dragHandle, setDragHandle] = useState<"top" | "bottom" | "middle">(
    "top"
  );
  const [cursorYPos, setCursorYPos] = useState(0);

  const locationTimeBlocks: LocationTimeBlock[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!locationHistory) return [];
    return createTimeBlocksFromLocationHistory(dayBounds, locationHistory);
  }, [dayBounds, locationHistory]);

  /**
   * Rounds a timestamp in milliseconds to the nearest snap point.
   */
  const roundToSnap = useCallback(
    (mills: number) => {
      return Math.round(mills / snapToMills) * snapToMills;
    },
    [snapToMills]
  );

  /**
   * Calculates the new top and bottom positions for a timeslot during dragging.
   * Is called by the useMemo to calculate the dragTopPx and dragBottomPx.
   */
  const calculateDragTopBottom = useCallback(
    (
      timeslot: Timeslot,
      dragHandle: "top" | "bottom" | "middle",
      cursorYPos: number,
      dragStartY: number,
      dayBounds: number[]
    ) => {
      // Calculate original top and bottom positions
      const ogTop = (timeslot.startTimestampMills - dayBounds[0]) * pxPerMs; // Original top position in pixels
      const ogBottom = (timeslot.endTimestampMills - dayBounds[0]) * pxPerMs; // Original bottom position in pixels
      const dragDelta = cursorYPos - dragStartY; // Calculate drag distance

      // Determine new top and bottom based on drag handle
      let top = dragHandle === "bottom" ? ogTop : ogTop + dragDelta;
      let bottom = dragHandle === "top" ? ogBottom : ogBottom + dragDelta;

      // Ensure top is always less than bottom
      if (top > bottom) [top, bottom] = [bottom, top];
      top = Math.max(0, top); // Ensure top is not less than 0
      bottom = Math.min(24 * pxPerHr, bottom); // Ensure bottom is not greater than 24 hours

      // Calculate and round top and bottom to nearest snap point
      const topMills = roundToSnap(top / pxPerMs);
      const bottomMills = roundToSnap(bottom / pxPerMs);

      // Set drag top and bottom mills
      setDragTopMills(Math.round(topMills + dayBounds[0])); // Set drag top mills
      setDragBottomMills(Math.round(bottomMills + dayBounds[0])); // Set drag bottom mills

      // Return new top and bottom positions in pixels
      return { top: topMills * pxPerMs, bottom: bottomMills * pxPerMs };
    },
    [pxPerHr, pxPerMs, roundToSnap]
  );

  /**
   * Updates the dragged timeslot with the new start and end times.
   */
  const getUpdatedDraggedTimeslot = useCallback(
    (timeslots: Timeslot[], draggingTimeslotId: string | null) => {
      const draggedTimeslot = timeslots.find(
        (t) => t.id === draggingTimeslotId
      );
      if (!draggedTimeslot)
        return { updatedTimeslot: null, start: null, end: null };

      const startTimeNearestMinute = Math.round(dragTopMills / 1000 / 60);
      const endTimeNearestMinute = Math.round(dragBottomMills / 1000 / 60);

      const startTimestampMills = startTimeNearestMinute * 60 * 1000;
      const endTimestampMills = endTimeNearestMinute * 60 * 1000;

      return {
        updatedTimeslot: {
          ...draggedTimeslot,
          startTimestampMills,
          endTimestampMills,
        },
        start: startTimeNearestMinute,
        end: endTimeNearestMinute,
      };
    },
    [dragTopMills, dragBottomMills]
  );

  const releaseDrag = useCallback(async () => {
    // Check if all necessary data is available
    if (!draggingTimeslot || !dayBounds || !timeslots) return;

    setConflictingTimeslots([]);
    setButtonDragging(false);
    setDraggingTimeslot(null);

    const { updatedTimeslot, start, end } = getUpdatedDraggedTimeslot(
      timeslots,
      draggingTimeslot.id
    );
    if (!updatedTimeslot) return;

    // Get and apply timeslot adjustments
    const timeslotChanges = await getTimeslotAdjustments(
      timeslots,
      updatedTimeslot
    );
    const undoSteps = await applyTimeslotChanges(
      cloudSyncedDB,
      [
        ...timeslotChanges,
        {
          action: "update",
          timeslot: {
            ...updatedTimeslot,
            startTimestampMills: start * 60 * 1000,
            endTimestampMills: end * 60 * 1000,
          },
        },
      ],
      true
    );

    setTimeout(() => {
      // Push undo steps and reset state
      pushUndoSteps(undoSteps, true);
    }, 20);
  }, [
    dayBounds,
    draggingTimeslot,
    pushUndoSteps,
    timeslots,
    getUpdatedDraggedTimeslot,
    cloudSyncedDB,
  ]);

  const timeslotBoundaries: number[] = useMemo(() => {
    if (!timeslots) return [];
    if (!dayBounds) return [];
    return generateTimeslotBoundaries(timeslots, dayBounds);
  }, [timeslots, dayBounds]);

  /**
   * Merges a timeslot up or down based on the specified direction to the previous or next timeslot boundary.
   */
  const processMerges = useCallback(
    async (timeslotId: string | null, direction: "up" | "down") => {
      if (!dayBounds || !timeslots || !timeslotId) return;

      const timeslot = timeslots.find((t) => t.id === timeslotId);
      if (!timeslot) return;

      // Determine new start and end times based on direction
      const [newStart, newEnd] =
        direction === "up"
          ? [
              // Get the last boundary that is less than the timeslot's start time
              timeslotBoundaries
                .filter((b) => b < timeslot.startTimestampMills)
                .pop() || timeslot.startTimestampMills,
              timeslot.endTimestampMills,
            ]
          : [
              timeslot.startTimestampMills,
              // Get the first boundary that is greater than the timeslot's end time
              timeslotBoundaries.find((b) => b > timeslot.endTimestampMills) ||
                timeslot.endTimestampMills,
            ];

      if (
        newStart === timeslot.startTimestampMills &&
        newEnd === timeslot.endTimestampMills
      )
        return;

      const updatedTimeslot = {
        ...timeslot,
        startTimestampMills: newStart,
        endTimestampMills: newEnd,
      };

      // Get and apply timeslot adjustments
      const timeslotChanges = await getTimeslotAdjustments(
        timeslots,
        updatedTimeslot
      );
      const undoSteps = await applyTimeslotChanges(
        cloudSyncedDB,
        [...timeslotChanges, { action: "update", timeslot: updatedTimeslot }],
        true
      );

      pushUndoSteps(undoSteps, true);
    },
    [dayBounds, timeslots, timeslotBoundaries, pushUndoSteps, cloudSyncedDB]
  );

  /**
   * Updates the cursor position and checks for conflicts.
   */
  const mouseMove = useCallback(
    (yPos: number) => {
      setCursorYPos(yPos);

      if (!timeslots) return;

      const { updatedTimeslot } = getUpdatedDraggedTimeslot(
        timeslots,
        draggingTimeslot?.id || null
      );
      if (!updatedTimeslot) return;

      // Check for conflicts
      const conflicts = getConflictingTimeslots(timeslots, updatedTimeslot);
      if (conflicts.length > 0) {
        // Highlight conflicting timeslots
        setConflictingTimeslots(conflicts.map((t) => t.id));
      } else {
        // No conflicts, clear any previously highlighted conflicts
        setConflictingTimeslots([]);
      }
    },
    [draggingTimeslot, timeslots, getUpdatedDraggedTimeslot]
  );

  const blankTimeslots: BlankTimeslot[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!timeslots) return [];
    // Calculate blank timeslots as the inverse of timeslots
    const blankTimeslots: BlankTimeslot[] = getBlankTimeRanges(
      timeslots,
      dayBounds
    ).map((range) => ({
      startTimestampMills: range[0],
      endTimestampMills: range[1],
    }));
    return blankTimeslots;
  }, [dayBounds, timeslots]);

  // Get the positions of the drag buttons for each timeslot
  const buttonPositions: number[] = useMemo(() => {
    if (!dayBounds) return [];
    if (!timeslots) return [];
    if (!timeslots.length) return [];
    if (selectedTimeslot === null) return [];

    const timeslot = timeslots.find((t) => t.id === selectedTimeslot.id);

    if (!timeslot) return [];

    const top = (timeslot.startTimestampMills - dayBounds[0]) * pxPerMs;
    const bottom = (timeslot.endTimestampMills - dayBounds[0]) * pxPerMs;
    const middle = (top + bottom) / 2;
    return [top, middle, bottom];
  }, [dayBounds, timeslots, selectedTimeslot, pxPerMs]);

  /**
   * Calculates the new top and bottom positions for a timeslot during dragging.
   */
  const { dragTopPx, dragBottomPx } = useMemo(() => {
    if (!dayBounds) return { dragTopPx: 0, dragBottomPx: 0 };
    if (!timeslots) return { dragTopPx: 0, dragBottomPx: 0 };
    if (!timeslots.length) return { dragTopPx: 0, dragBottomPx: 0 };
    if (!selectedTimeslot) return { dragTopPx: 0, dragBottomPx: 0 };
    const timeslot = timeslots.find((t) => t.id === selectedTimeslot?.id);
    if (!timeslot) return { dragTopPx: 0, dragBottomPx: 0 };

    const { top, bottom } = calculateDragTopBottom(
      timeslot,
      dragHandle,
      cursorYPos,
      dragStartY,
      dayBounds
    );

    return { dragTopPx: top, dragBottomPx: bottom };
  }, [
    dayBounds,
    timeslots,
    selectedTimeslot,
    calculateDragTopBottom,
    dragHandle,
    cursorYPos,
    dragStartY,
  ]);

  const addButtonPosition: AddButtonPosition | null = useMemo(() => {
    if (!dayBounds) return null;
    if (!timeslotBoundaries) return null;
    if (!timeslots) return null;

    const SNAP_THRESHOLD_PX = 10; // Pixels within which to snap to a boundary
    const ADD_BUTTON_HEIGHT = defaultTimeslotDuration * pxPerMs; // Constant height for add button

    // Calculate cursor position in milliseconds
    const cursorMills = cursorYPos / pxPerMs + dayBounds[0];

    // Round to nearest snap point
    let addPoint = Math.round(cursorMills / snapToMills) * snapToMills;
    let buttonHeight = ADD_BUTTON_HEIGHT;

    // Get all potential snap points including timeslot edges and day bounds
    const allSnapPoints = [
      ...dayBounds,
      ...timeslotBoundaries,
      currentTimeMills,
    ];

    // Check if cursor or button edges are near any snap points
    const cursorPx = (cursorMills - dayBounds[0]) * pxPerMs;
    const topEdgePx = cursorPx - ADD_BUTTON_HEIGHT / 2;
    const bottomEdgePx = cursorPx + ADD_BUTTON_HEIGHT / 2;

    const nearestBoundary = {
      middle: allSnapPoints.find((boundary) => {
        const boundaryPx = (boundary - dayBounds[0]) * pxPerMs;
        return Math.abs(boundaryPx - cursorPx) < SNAP_THRESHOLD_PX;
      }),
      top: allSnapPoints.find((boundary) => {
        const boundaryPx = (boundary - dayBounds[0]) * pxPerMs;
        return Math.abs(boundaryPx - topEdgePx) < SNAP_THRESHOLD_PX;
      }),
      bottom: allSnapPoints.find((boundary) => {
        const boundaryPx = (boundary - dayBounds[0]) * pxPerMs;
        return Math.abs(boundaryPx - bottomEdgePx) < SNAP_THRESHOLD_PX;
      }),
    };

    // If near a boundary, snap to it
    if (nearestBoundary.middle) {
      addPoint = nearestBoundary.middle;
    } else if (nearestBoundary.top) {
      addPoint = nearestBoundary.top;
      addPoint += ADD_BUTTON_HEIGHT / pxPerMs / 2;
    } else if (nearestBoundary.bottom) {
      addPoint = nearestBoundary.bottom;
      addPoint -= ADD_BUTTON_HEIGHT / pxPerMs / 2;
    }

    // Center the button on the snap point
    addPoint -= ADD_BUTTON_HEIGHT / pxPerMs / 2;

    // Handle clipping at day boundaries
    if (addPoint < dayBounds[0]) {
      addPoint = dayBounds[0];
      buttonHeight = Math.min(
        ADD_BUTTON_HEIGHT,
        (dayBounds[1] - addPoint) * pxPerMs
      );
    } else if (addPoint + ADD_BUTTON_HEIGHT / pxPerMs > dayBounds[1]) {
      buttonHeight = Math.max(0, (dayBounds[1] - addPoint) * pxPerMs);
    }

    // Convert back to pixels for display
    const addPixel = (addPoint - dayBounds[0]) * pxPerMs;

    return {
      addMs: addPoint,
      addPx: addPixel,
      addHeightPx: buttonHeight,
      addMsLength: buttonHeight / pxPerMs,
    };
  }, [
    dayBounds,
    timeslotBoundaries,
    timeslots,
    defaultTimeslotDuration,
    pxPerMs,
    cursorYPos,
    snapToMills,
    currentTimeMills,
  ]);

  const shouldShowDragButtons: boolean = useMemo(() => {
    if (editingTimeslot || buttonDragging || newTimeslotParams !== null)
      return false;

    return buttonPositions.length > 0;
  }, [buttonDragging, newTimeslotParams, buttonPositions, editingTimeslot]);

  const shouldShowAddButton: boolean = useMemo(() => {
    if (editingTimeslot || buttonDragging || newTimeslotParams !== null)
      return false;
    return addButtonPosition !== null;
  }, [editingTimeslot, buttonDragging, newTimeslotParams, addButtonPosition]);

  useEffect(() => {
    const handleMouseUp = () => {
      if (buttonDragging) {
        releaseDrag();
      }
    };

    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [buttonDragging, releaseDrag]);

  return (
    <Stack
      direction="row"
      gap={1}
      marginTop="50px"
      marginBottom="50px"
      paddingX="16px"
      onMouseMove={(e) => {
        mouseMove(e.clientY - e.currentTarget.getBoundingClientRect().top);
      }}>
      {dayBounds && (
        <>
          <TimeslotColumn
            direction="column"
            width="100%"
            gap="1px"
            padding="1px">
            {hours.map((hour, index) => (
              <Stack direction="row" key={index} width="100%">
                <HourLabel backgroundColor={hourLabelBackgroundColor(hour)}>
                  <div>{`${hour.toString().padStart(2, "0")}:00`}</div>
                </HourLabel>

                <Hour
                  height={pxPerHr}
                  key={index}
                  onMouseEnter={() => {
                    if (!draggingTimeslot) {
                      setHoveredTimeslot(null);
                    }
                  }}
                  onClick={() => {
                    deselectAll();
                  }}></Hour>
              </Stack>
            ))}
            {shouldShowDragButtons && (
              <>
                <DragButton
                  sx={{
                    top: `${buttonPositions[1] - 15}px`,
                  }}
                  className="handle middle"
                  variant="text"
                  onMouseDown={() => {
                    setButtonDragging(true);
                    setDragStartY(cursorYPos);
                    setDraggingTimeslot(selectedTimeslot);
                    setDragHandle("middle");
                  }}>
                  <DragHandle className="handleIcon" />
                </DragButton>
                <ScaleButton
                  sx={{
                    top: `${buttonPositions[0] - 5}px`,
                  }}
                  className="handle top"
                  variant="text"
                  onMouseDown={() => {
                    setButtonDragging(true);
                    setDragStartY(cursorYPos);
                    setDraggingTimeslot(selectedTimeslot);
                    setDragHandle("top");
                  }}>
                  <DragHandle className="handleIcon" />
                </ScaleButton>

                <ScaleButton
                  sx={{
                    top: `${buttonPositions[2] - 5}px`,
                  }}
                  className="handle bottom"
                  variant="text"
                  onMouseDown={() => {
                    setButtonDragging(true);
                    setDragStartY(cursorYPos);
                    setDraggingTimeslot(selectedTimeslot);
                    setDragHandle("bottom");
                  }}>
                  <DragHandle className="handleIcon" />
                </ScaleButton>
                <MergeButton
                  sx={{
                    top: `${buttonPositions[0] - 10}px`,
                  }}
                  variant="text"
                  onClick={() => {
                    processMerges(selectedTimeslot?.id || null, "up");
                  }}>
                  <ArrowUpward className="handleIcon" />
                </MergeButton>
                <MergeButton
                  sx={{
                    top: `${buttonPositions[2] - 10}px`,
                  }}
                  variant="text"
                  onClick={() => {
                    processMerges(selectedTimeslot?.id || null, "down");
                  }}>
                  <ArrowDownward className="handleIcon" />
                </MergeButton>
              </>
            )}

            {timeslotBoundaries.map((mills, index) => {
              // If this belongs to the highlighted timeslot, or dragged timeslot, don't show it
              if (
                [
                  draggingTimeslot?.startTimestampMills,
                  draggingTimeslot?.endTimestampMills,
                ].includes(mills)
              ) {
                return null;
              }

              // Convert mills to px
              const px = (mills - dayBounds[0]) * pxPerMs;
              // Convert mills to time using date-fns
              const time = new TZDate(mills, getComputerTimezone());
              const timeString = format(time, "HH:mm");
              return (
                <TimeDisplay key={index + mills} top={px - 10}>
                  {timeString}
                </TimeDisplay>
              );
            })}

            {(hoveredTimeslot || draggingTimeslot) &&
              (() => {
                let topTime = hoveredTimeslot?.startTimestampMills || 0;
                let bottomTime = hoveredTimeslot?.endTimestampMills || 0;
                if (draggingTimeslot) {
                  topTime = dragTopMills;
                  bottomTime = dragBottomMills;
                }
                const topTimeString = format(
                  new TZDate(topTime, getComputerTimezone()),
                  "HH:mm"
                );
                const bottomTimeString = format(
                  new TZDate(bottomTime, getComputerTimezone()),
                  "HH:mm"
                );
                return (
                  <>
                    <TimeDisplay
                      top={(topTime - dayBounds[0]) * pxPerMs - 10}
                      theme="hover">
                      {topTimeString}
                    </TimeDisplay>
                    <TimeDisplay
                      top={(bottomTime - dayBounds[0]) * pxPerMs - 10}
                      theme="hover">
                      {bottomTimeString}
                    </TimeDisplay>
                  </>
                );
              })()}
            {!draggingTimeslot &&
              blankTimeslots.map((timeslot) => (
                <BlankTimeslotComponent
                  key={
                    timeslot.startTimestampMills + timeslot.endTimestampMills
                  }
                  startTimestampMills={timeslot.startTimestampMills}
                  endTimestampMills={timeslot.endTimestampMills}
                  dayBounds={dayBounds}
                  pxPerMs={pxPerMs}
                  onMouseEnter={() => {
                    if (!draggingTimeslot) {
                      setHoveredTimeslot(null);
                    }
                  }}
                  onClick={() => {
                    deselectAll();
                  }}
                  onDoubleClick={() => {
                    openAddTimeslot(
                      timeslot.startTimestampMills,
                      timeslot.endTimestampMills - timeslot.startTimestampMills
                    );
                  }}
                />
              ))}
            {timeslots
              ?.filter((t) => draggingTimeslot?.id !== t.id)
              .map((timeslot) => (
                <TimeslotComponent
                  key={timeslot.id + timeslot.startTimestampMills}
                  timeslot={timeslot}
                  dayBounds={dayBounds}
                  pxPerMs={pxPerMs}
                  activityCategoryMap={activityCategoryMap}
                  isSelected={selectedTimeslot?.id === timeslot.id}
                  isConflicting={conflictingTimeslots.includes(timeslot.id)}
                  isEditing={editingTimeslot === timeslot.id}
                  onMouseEnter={() => {
                    if (!draggingTimeslot) {
                      setHoveredTimeslot(timeslot);
                    }
                  }}
                  onClick={() => {
                    deselectAll();
                    setSelectedTimeslot(timeslot);
                  }}
                  onDoubleClick={() => {
                    if (editingTimeslot !== timeslot.id) {
                      setEditingTimeslot(timeslot.id);
                    } else {
                      setEditingTimeslot(null);
                    }
                  }}
                />
              ))}
            {draggingTimeslot && (
              <DraggingTimeslotComponent
                timeslot={draggingTimeslot}
                activity={activityCategoryMap[draggingTimeslot.activityId]}
                top={dragTopPx}
                bottom={dragBottomPx}
                dayBounds={dayBounds}
                pxPerMs={pxPerMs}
              />
            )}
            {shouldShowAddButton && (
              <AddButton
                height={addButtonPosition?.addHeightPx || pxPerHr}
                variant="outlined"
                sx={{
                  top: `${addButtonPosition?.addPx}px`,
                }}
                onClick={() => {
                  if (addButtonPosition) {
                    openAddTimeslot(
                      addButtonPosition.addMs,
                      addButtonPosition.addMsLength
                    );
                  }
                }}>
                +
                {addButtonPosition?.addMs &&
                  addButtonPosition.addMsLength > 0 &&
                  dayBounds && (
                    <>
                      <div className="timeLabel top">
                        {new Date(addButtonPosition.addMs).toLocaleTimeString(
                          "en-GB",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </div>
                      {addButtonPosition.addHeightPx > 50 && (
                        <div className="timeLabel bottom">
                          {new Date(
                            addButtonPosition.addMs +
                              addButtonPosition.addMsLength
                          ).toLocaleTimeString("en-GB", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </div>
                      )}
                    </>
                  )}
              </AddButton>
            )}

            {newTimeslotParams && (
              <NewTimeslotDraggableIndicator
                newTimeslotParams={newTimeslotParams}
                pxPerMs={pxPerMs}
                dayBounds={dayBounds}
                setNewTimeslotTimes={(times) => {
                  setNewTimeslotParams({
                    ...newTimeslotParams,
                    ...times,
                  });
                }}
              />
            )}

            <CurrentTimeLine
              dayStartMills={dayBounds[0]}
              pxPerMs={pxPerMs}
              currentTimeMills={currentTimeMills}
              showTime={true}
            />
          </TimeslotColumn>

          {showLocationHistory && (
            <LocationHistoryColumn
              dayBounds={dayBounds}
              pxPerMs={pxPerMs}
              currentTimeMills={currentTimeMills}
              locationHistory={locationHistory}
              locationTimeBlocks={locationTimeBlocks}
              applyPlaceToTimeslots={applyPlaceToTimeslots}
              setNewTimeslotParams={setNewTimeslotParams}
            />
          )}
        </>
      )}
    </Stack>
  );
};
