import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { defaultSettings, SettingsType } from "./useSettings";
import useStorageBackedState from "./useStorageBackedState";

export const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);

export type StartAnalyticsScreen = "recents" | "trends" | "calendar";

export interface SettingsContextType {
  showLocation: boolean;
  snapToMills: number;
  defaultTimeslotDuration: number;
  defaultZoom: number;
  setShowLocation: (showLocation: boolean) => void;
  setSnapToMills: (snapToMills: number) => void;
  setDefaultZoom: (defaultZoom: number) => void;
  setDefaultTimeslotDuration: (defaultTimeslotDuration: number) => void;
  settingsAsJson: string;
  locationHistoryEndpoint: string;
  setLocationHistoryEndpoint: (locationHistoryEndpoint: string) => void;
  startAnalyticsScreen: StartAnalyticsScreen;
  setStartAnalyticsScreen: (startAnalyticsScreen: StartAnalyticsScreen) => void;
  setSettingsFromJson: (json: string) => void;
  cloudSyncEnabled: boolean;
  cloudBackupEndpoint: string;
  cloudSyncKey: string;
  setCloudSyncEnabled: (cloudSyncEnabled: boolean) => void;
  enableCloudSync: (cloudBackupEndpoint: string, cloudSyncKey: string) => void;
  disableCloudSync: () => void;
  setLastCloudChangeTimestamp: (lastCloudChangeTimestamp: number) => void;
  lastCloudChangeTimestamp: number;
  autoSyncLocationHistory: boolean;
  autoSyncBackups: boolean;
  setAutoSyncLocationHistory: (autoSyncLocationHistory: boolean) => void;
  setAutoSyncBackups: (autoSyncBackups: boolean) => void;
  syncBatchSize: number;
  setSyncBatchSize: (syncBatchSize: number) => void;
  developerMode: boolean;
  setDeveloperMode: (developerMode: boolean) => void;
  dateFormat: string;
  setDateFormat: (dateFormat: string) => void;
}

export const SettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [settings, setSettings] = useStorageBackedState<
    Record<SettingsType, boolean | number | string>
  >(defaultSettings, "settings");

  useEffect(() => {
    // Use defaults to fill in any missing keys
    let anyMissingKeys = false;
    for (const key in defaultSettings) {
      if (!(key in settings)) {
        settings[key as SettingsType] = defaultSettings[key as SettingsType];
        anyMissingKeys = true;
      }
    }
    if (anyMissingKeys) {
      setSettings(settings as Record<SettingsType, boolean | number | string>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSetting = useCallback(
    (setting: SettingsType, value: boolean | number | string) => {
      const newSettings = { ...settings, [setting]: value };
      setSettings(
        newSettings as Record<SettingsType, boolean | number | string>
      );
    },
    [settings, setSettings]
  );

  const settingsAsJson = useMemo(() => {
    if (!settings) return "";
    return JSON.stringify(settings);
  }, [settings]);

  const setSettingsFromJson = useCallback(
    (json: string) => {
      try {
        const parsedSettings = JSON.parse(json);
        setSettings(
          parsedSettings as Record<SettingsType, boolean | number | string>
        );
      } catch (error) {
        console.error("Invalid JSON format for settings:", error);
      }
    },
    [setSettings]
  );

  const showLocation = useMemo(
    () => settings?.[SettingsType.ShowLocation] as boolean,
    [settings]
  );

  const setShowLocation = useCallback(
    (showLocation: boolean) => {
      setSetting(SettingsType.ShowLocation, showLocation);
    },
    [setSetting]
  );

  const snapToMills = useMemo(
    () => settings?.[SettingsType.SnapToMills] as number,
    [settings]
  );
  const setSnapToMills = useCallback(
    (snapToMills: number) => {
      setSetting(SettingsType.SnapToMills, snapToMills);
    },
    [setSetting]
  );

  const defaultZoom = useMemo(
    () => settings?.[SettingsType.DefaultZoom] as number,
    [settings]
  );

  const setDefaultZoom = useCallback(
    (defaultZoom: number) => {
      setSetting(SettingsType.DefaultZoom, defaultZoom);
    },
    [setSetting]
  );

  const defaultTimeslotDuration = useMemo(
    () => settings?.[SettingsType.DefaultTimeslotDuration] as number,
    [settings]
  );

  const setDefaultTimeslotDuration = useCallback(
    (defaultTimeslotDuration: number) => {
      setSetting(SettingsType.DefaultTimeslotDuration, defaultTimeslotDuration);
    },
    [setSetting]
  );

  const locationHistoryEndpoint = useMemo(
    () => settings?.[SettingsType.LocationHistoryEndpoint] as string,
    [settings]
  );
  const setLocationHistoryEndpoint = useCallback(
    (locationHistoryEndpoint: string) => {
      setSetting(SettingsType.LocationHistoryEndpoint, locationHistoryEndpoint);
    },
    [setSetting]
  );

  const startAnalyticsScreen = useMemo(
    () => settings?.[SettingsType.StartAnalyticsScreen] as StartAnalyticsScreen,
    [settings]
  );
  const setStartAnalyticsScreen = useCallback(
    (startAnalyticsScreen: StartAnalyticsScreen) => {
      setSetting(SettingsType.StartAnalyticsScreen, startAnalyticsScreen);
    },
    [setSetting]
  );

  const cloudSyncEnabled = useMemo(() => {
    return settings?.[SettingsType.CloudSyncEnabled] as boolean;
  }, [settings]);
  const setCloudSyncEnabled = useCallback(
    (cloudSyncEnabled: boolean) => {
      setSetting(SettingsType.CloudSyncEnabled, cloudSyncEnabled);
    },
    [setSetting]
  );

  const enableCloudSync = useCallback(
    (cloudBackupEndpoint: string, cloudSyncKey: string) => {
      const newSettings = {
        ...settings,
        [SettingsType.CloudBackupEndpoint]: cloudBackupEndpoint,
        [SettingsType.CloudSyncEnabled]: true,
        [SettingsType.CloudSyncKey]: cloudSyncKey,
        [SettingsType.LastCloudChangeTimestamp]: 0,
      };
      setSettings(
        newSettings as Record<SettingsType, boolean | number | string>
      );
    },
    [setSettings, settings]
  );

  const disableCloudSync = useCallback(() => {
    const newSettings = {
      ...settings,
      [SettingsType.CloudBackupEndpoint]: "",
      [SettingsType.CloudSyncEnabled]: false,
      [SettingsType.CloudSyncKey]: "",
      [SettingsType.LastCloudChangeTimestamp]: 0,
    };
    setSettings(newSettings as Record<SettingsType, boolean | number | string>);
  }, [setSettings, settings]);

  const cloudBackupEndpoint = useMemo(
    () => settings?.[SettingsType.CloudBackupEndpoint] as string,
    [settings]
  );

  const cloudSyncKey = useMemo(
    () => settings?.[SettingsType.CloudSyncKey] as string,
    [settings]
  );

  const lastCloudChangeTimestamp = useMemo(
    () => settings?.[SettingsType.LastCloudChangeTimestamp] as number,
    [settings]
  );

  const setLastCloudChangeTimestamp = useCallback(
    (lastCloudChangeTimestamp: number) => {
      console.log(
        "Setting last cloud change timestamp:",
        lastCloudChangeTimestamp
      );
      setSetting(
        SettingsType.LastCloudChangeTimestamp,
        lastCloudChangeTimestamp
      );
    },
    [setSetting]
  );

  const autoSyncLocationHistory = useMemo(
    () => settings?.[SettingsType.AutoSyncLocationHistory] as boolean,
    [settings]
  );

  const setAutoSyncLocationHistory = useCallback(
    (autoSyncLocationHistory: boolean) => {
      setSetting(SettingsType.AutoSyncLocationHistory, autoSyncLocationHistory);
    },
    [setSetting]
  );

  const autoSyncBackups = useMemo(
    () => settings?.[SettingsType.AutoSyncBackups] as boolean,
    [settings]
  );

  const setAutoSyncBackups = useCallback(
    (autoSyncBackups: boolean) => {
      setSetting(SettingsType.AutoSyncBackups, autoSyncBackups);
    },
    [setSetting]
  );

  const syncBatchSize = useMemo(
    () => settings?.[SettingsType.SyncBatchSize] as number,
    [settings]
  );
  const setSyncBatchSize = useCallback(
    (syncBatchSize: number) => {
      setSetting(SettingsType.SyncBatchSize, syncBatchSize);
    },
    [setSetting]
  );

  const developerMode = useMemo(
    () => settings?.[SettingsType.DeveloperMode] as boolean,
    [settings]
  );
  const setDeveloperMode = useCallback(
    (developerMode: boolean) => {
      setSetting(SettingsType.DeveloperMode, developerMode);
    },
    [setSetting]
  );

  const dateFormat = useMemo(
    () => settings?.[SettingsType.DateFormat] as string,
    [settings]
  );
  const setDateFormat = useCallback(
    (dateFormat: string) => {
      setSetting(SettingsType.DateFormat, dateFormat);
    },
    [setSetting]
  );

  return (
    <SettingsContext.Provider
      value={{
        showLocation,
        snapToMills,
        setShowLocation,
        setSnapToMills,
        settingsAsJson,
        setSettingsFromJson,
        defaultZoom,
        setDefaultZoom,
        defaultTimeslotDuration,
        setDefaultTimeslotDuration,
        locationHistoryEndpoint,
        setLocationHistoryEndpoint,
        startAnalyticsScreen,
        setStartAnalyticsScreen,
        cloudSyncEnabled,
        cloudBackupEndpoint,
        cloudSyncKey,
        setCloudSyncEnabled,
        enableCloudSync,
        disableCloudSync,
        lastCloudChangeTimestamp,
        setLastCloudChangeTimestamp,
        autoSyncLocationHistory,
        autoSyncBackups,
        setAutoSyncLocationHistory,
        setAutoSyncBackups,
        syncBatchSize,
        setSyncBatchSize,
        developerMode,
        setDeveloperMode,
        dateFormat,
        setDateFormat,
      }}>
      {children}
    </SettingsContext.Provider>
  );
};
