import {
  Analytics as AnalyticsIcon,
  DnsRounded as DataIcon,
  Home as HomeIcon,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  CssBaseline,
  Drawer,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { AppHeader } from "./components/AppHeader";
import { DrawerList } from "./components/DrawerList";
import { KeyIcon } from "./components/KeyIcon";
import { useModal } from "./hooks/useModal";
import { useSyncing } from "./hooks/useSyncing";
import { initializeAppIfNeeded, isAppInitialised } from "./utils/db_helpers";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  // Determine the active tab based on the current path
  const getActiveTab = () => {
    const path = location.pathname.split("/")[1]; // Get the first part of the path
    if (path === "" || path === "home") return "home";
    if (path === "analytics") return "analytics";
    if (path === "data") return "data";
    return "";
  };

  const [value, setValue] = useState(getActiveTab());
  const [open, setOpen] = useState(false);

  // Update the value when the location changes
  useEffect(() => {
    setValue(getActiveTab());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { showModal } = useModal();
  useEffect(() => {
    const initialiseApp = async () => {
      if (!(await isAppInitialised())) {
        showModal(
          "Welcome to Dumbertime!",
          <>
            <Typography>
              This application is still in beta, expect bugs and bad UX.
            </Typography>
            <Typography component="ul">
              <li>Kindly refer to the instructions in the hamburger menu.</li>
              <li>This application is optimised for desktop use only.</li>
              <li>
                Your data is stored locally; please avoid clearing your cache.
              </li>
              <li>
                Cloud sync is currently in beta, and there may be some issues.
              </li>
              <li>Please report any bugs to Xun. Thank you.</li>
            </Typography>
          </>,
          {
            icon: <InfoIcon />,
            okText: "No problem",
            cancelText: false,
          }
        );
      }
      initializeAppIfNeeded();
    };
    initialiseApp();
  }, [showModal]);

  const { syncLocationHistory, syncBackups } = useSyncing();

  const toggleDrawer = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  useEffect(() => {
    const interval = setInterval(() => {
      syncLocationHistory(false);
      syncBackups(false);
    }, 300000); // 5 minutes

    const timeout = setTimeout(() => {
      syncLocationHistory(false);
      syncBackups(false);
    }, 100000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [syncBackups, syncLocationHistory]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      if (open) {
        return;
      }
      switch (event.key) {
        case "1":
          if (value !== "home") {
            navigate("/");
            setValue("home");
          }
          break;
        case "2":
          if (value !== "analytics") {
            navigate("/analytics");
            setValue("analytics");
          }
          break;
        case "3":
          if (value !== "data") {
            navigate("/data");
            setValue("data");
          }
          break;
        case "`":
          toggleDrawer();
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [navigate, open, setValue, toggleDrawer, value]);

  return (
    <>
      <CssBaseline />

      <Stack
        sx={{
          height: "100%",
          maxHeight: "100%",
        }}
        direction="column"
        justifyContent="space-between">
        <AppHeader toggleDrawer={toggleDrawer} />
        <Container
          className="scroll-container"
          sx={{
            flexGrow: "1",
            overflowY: "auto",
          }}>
          <Outlet />
        </Container>
        <Paper elevation={3}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(_, newValue) => {
              navigate(newValue === "home" ? "/" : `/${newValue}`);
              setValue(newValue);
            }}>
            <BottomNavigationAction
              value={"home"}
              label={
                <Typography
                  sx={{
                    fontSize: "0.76rem",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  Home <KeyIcon keyName="1" />
                </Typography>
              }
              icon={<HomeIcon />}
            />

            <BottomNavigationAction
              value={"analytics"}
              label={
                <Typography
                  sx={{
                    fontSize: "0.76rem",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  Analytics <KeyIcon keyName="2" />
                </Typography>
              }
              icon={<AnalyticsIcon />}
            />
            <BottomNavigationAction
              value={"data"}
              label={
                <Typography
                  sx={{
                    fontSize: "0.76rem",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  Data <KeyIcon keyName="3" />
                </Typography>
              }
              icon={<DataIcon />}
            />
          </BottomNavigation>
        </Paper>
      </Stack>
      <Drawer open={open} onClose={() => setOpen(false)} sx={{ zIndex: 2000 }}>
        <DrawerList closeDrawer={() => setOpen(false)} />
      </Drawer>
    </>
  );
}

export default App;
