import { TZDate } from "@date-fns/tz";
import { addDays, format } from "date-fns";

export const dateStringToDayBoundsMills = (
  date: string,
  timezone: string
): [number, number] | null => {
  try {
    const [year, month, day] = date.split("-").map((s) => parseInt(s));
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return null;
    }
    const dS = new TZDate(year, month - 1, day, 0, 0, 0, 0, timezone);
    const dE = addDays(dS, 1);
    if (isNaN(dS.getTime()) || isNaN(dE.getTime())) {
      return null;
    }
    return [dS.getTime(), dE.getTime()];
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getComputerTimezone = (): string => {
  const timezone = format(new Date(), "xxx"); // +02:00
  return timezone;
};

export const timeDisplay = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  if (hours > 0 && mins > 0) {
    return `${hours}h ${mins}m`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else {
    return `${mins}m`;
  }
};

export const timeOfDay = (mills: number) => {
  return format(mills, "HH:mm");
};

export const heightFromMinutes = (minutes: number) => {
  if (minutes <= 15) {
    return 40;
  } else if (minutes <= 30) {
    return 50;
  } else if (minutes <= 60) {
    return 65;
  } else if (minutes <= 120) {
    // 2 hours
    return 80;
  } else if (minutes <= 240) {
    // 4 hours
    return 120;
  } else if (minutes <= 360) {
    // 6 hours
    return 160;
  } else if (minutes <= 720) {
    // 12 hours
    return 300;
  } else {
    // 24 hours
    return 500;
  }
};

export const hourLabelBackgroundColor = (hour: number) => {
  if (hour < 7 || hour >= 19) {
    return "#616BD144"; // Dark blue with transparency
  } else if (hour < 8 || hour >= 18) {
    return "#F5B75944"; // Orange with transparency
  }
  return "#FFFBD944"; // Light yellow with transparency
};
