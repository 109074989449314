import styled from "@emotion/styled";
import { QuestionMark } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ICONS } from "../../../../const/icons";
import { Timeslot } from "../../../../database/db";
import { ActivityJoinCategory } from "../../../../database/helpers";
import { timeDisplay } from "../../../../utils/time";
import { OUTLINE_COLORS } from "../../../../const/colours";

export const MID_TIMESLOT_HEIGHT = 40;
export const SHORT_TIMESLOT_HEIGHT = 20;

const TimeslotRow = styled(Box)<{
  isconflicting: string;
  isediting: string;
  isselected: string;
}>`
  position: absolute;
  left: 50px;
  width: calc(100% - 120px);
  z-index: 1;
  box-sizing: border-box;
  border: ${(props) =>
    props.isconflicting === "true"
      ? `2px solid ${OUTLINE_COLORS.conflicting}`
      : props.isediting === "true"
      ? `2px solid ${OUTLINE_COLORS.editing}`
      : props.isselected === "true"
      ? `1px solid ${OUTLINE_COLORS.selected}`
      : "1px solid #aaaaaa"};
  cursor: pointer;

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

interface TimeslotComponentProps {
  timeslot: Timeslot;
  dayBounds: number[];
  pxPerMs: number;
  activityCategoryMap: Record<string, ActivityJoinCategory>;
  isSelected: boolean;
  isConflicting: boolean;
  isEditing: boolean;
  onMouseEnter: () => void;
  onClick: () => void;
  onDoubleClick: () => void;
}

export const TimeslotComponent: React.FC<TimeslotComponentProps> = ({
  timeslot,
  dayBounds,
  pxPerMs,
  activityCategoryMap,
  isSelected,
  isConflicting,
  isEditing,
  onMouseEnter,
  onClick,
  onDoubleClick,
}) => {
  const activity = activityCategoryMap[timeslot.activityId];
  const Icon = activity ? ICONS[activity.icon] : QuestionMark;
  const colour = activity ? activity.colour : "#999999";
  const name = activity ? activity.name : "Unknown";
  const top = (timeslot.startTimestampMills - dayBounds[0]) * pxPerMs;
  const height =
    (timeslot.endTimestampMills - timeslot.startTimestampMills) * pxPerMs;
  const minutes =
    (timeslot.endTimestampMills - timeslot.startTimestampMills) / 1000 / 60;

  return (
    <TimeslotRow
      sx={{
        top: `${top}px`,
        height: `${height}px`,
        backgroundColor: isSelected ? `${colour}CC` : `${colour}33`,
        "&:hover": {
          backgroundColor: isSelected ? `${colour}CC` : `${colour}55`,
        },
        overflow: isEditing ? "visible" : "hidden",
      }}
      isselected={isSelected.toString()}
      isconflicting={isConflicting.toString()}
      isediting={isEditing.toString()}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onDoubleClick={onDoubleClick}>
      {height >= MID_TIMESLOT_HEIGHT ? (
        <Stack direction="row" padding={1} gap={1}>
          <Icon />
          <Typography textAlign="left" sx={{ flexGrow: 1 }}>
            {name}
          </Typography>
          {timeslot.placeId && <LocationOnIcon />}
          <Typography>{timeDisplay(minutes)}</Typography>
        </Stack>
      ) : height >= SHORT_TIMESLOT_HEIGHT ? (
        <Stack direction="row" alignItems="center" paddingX={1} gap={1}>
          <Icon sx={{ fontSize: "12px" }} />

          <Typography textAlign="left" sx={{ flexGrow: 1, fontSize: "12px" }}>
            {name}
          </Typography>

          {timeslot.placeId && <LocationOnIcon sx={{ fontSize: "12px" }} />}

          <Typography sx={{ fontSize: "12px" }}>
            {timeDisplay(minutes)}
          </Typography>
        </Stack>
      ) : null}
    </TimeslotRow>
  );
};

export default TimeslotComponent;
