import {
  QuestionMark as AboutIcon,
  CloudDone as CloudDoneIcon,
  CloudOff as CloudOffIcon,
  CloudQueue as CloudQueueIcon,
  Help as HelpIcon,
  ImportExport as ImportExportIcon,
  Settings as SettingsIcon,
  Sync as SyncIcon,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSyncing } from "../hooks/useSyncing";
import { useCallback, useEffect } from "react";
import { KeyIcon } from "./KeyIcon";

type DrawerListProps = {
  closeDrawer: () => void;
};

enum Actions {
  SETTINGS = "1",
  IMPORT_EXPORT = "2",
  ABOUT = "3",
  INSTRUCTIONS = "4",
  SYNC_HISTORY = "5",
  CLOUD = "6",
}

export const DrawerList = ({ closeDrawer }: DrawerListProps) => {
  const navigate = useNavigate();

  const {
    isSyncing,
    syncLocationHistory,
    syncBackups,
    cloudBackupChanges,
    cloudSyncedDB,
    cloudBackupEndpoint,
  } = useSyncing();

  const performAction = useCallback(
    async (action: Actions) => {
      switch (action) {
        case Actions.SETTINGS:
          navigate("settings");
          break;
        case Actions.IMPORT_EXPORT:
          navigate("importexport");
          break;
        case Actions.ABOUT:
          navigate("about");
          break;
        case Actions.INSTRUCTIONS:
          navigate("instructions");
          break;
        case Actions.SYNC_HISTORY:
          await syncLocationHistory();
          break;
        case Actions.CLOUD:
          await syncBackups();
          break;
      }
      closeDrawer();
    },
    [navigate, closeDrawer, syncLocationHistory, syncBackups]
  );

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (Object.values(Actions).includes(e.key as Actions)) {
        e.preventDefault();
        e.stopPropagation();
        performAction(e.key as Actions);
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [closeDrawer, performAction]);

  return (
    <Stack
      direction="column"
      sx={{ width: "100hw", maxWidth: "400px", paddingTop: "5rem" }}
      height="100%"
      role="presentation"
      onClick={closeDrawer}>
      <List>
        <ListItem key={"settings"} disablePadding>
          <ListItemButton onClick={() => performAction(Actions.SETTINGS)}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Settings"}
              sx={{
                marginRight: 4,
              }}
            />
            <KeyIcon keyName={Actions.SETTINGS} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"import/export"} disablePadding>
          <ListItemButton onClick={() => performAction(Actions.IMPORT_EXPORT)}>
            <ListItemIcon>
              <ImportExportIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Import / Export"}
              sx={{
                marginRight: 4,
              }}
            />
            <KeyIcon keyName={Actions.IMPORT_EXPORT} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"about"} disablePadding>
          <ListItemButton onClick={() => performAction(Actions.ABOUT)}>
            <ListItemIcon>
              <AboutIcon />
            </ListItemIcon>
            <ListItemText
              primary={"About"}
              sx={{
                marginRight: 4,
              }}
            />
            <KeyIcon keyName={Actions.ABOUT} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"instructions"} disablePadding>
          <ListItemButton onClick={() => performAction(Actions.INSTRUCTIONS)}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Instructions"}
              sx={{
                marginRight: 4,
              }}
            />
            <KeyIcon keyName={Actions.INSTRUCTIONS} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider sx={{ my: 1 }} />
      <List>
        <ListItem key={"sync_location_history"} disablePadding>
          <ListItemButton onClick={() => performAction(Actions.SYNC_HISTORY)}>
            <ListItemIcon>
              <SyncIcon />
            </ListItemIcon>
            <ListItemText
              primary={isSyncing ? "Syncing..." : "Sync Location History"}
              sx={{
                marginRight: 4,
              }}
            />
            <KeyIcon keyName={Actions.SYNC_HISTORY} />
          </ListItemButton>
        </ListItem>
        {cloudBackupEndpoint ? (
          cloudBackupChanges && cloudBackupChanges > 0 ? (
            <ListItem key={"cloud_backup"} disablePadding>
              <ListItemButton onClick={() => performAction(Actions.CLOUD)}>
                <ListItemIcon>
                  <CloudQueueIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`Changes: ${cloudBackupChanges}`}
                  sx={{
                    marginRight: 4,
                  }}
                />
                <KeyIcon keyName={Actions.CLOUD} />
              </ListItemButton>
            </ListItem>
          ) : cloudSyncedDB.downloadingChanges ? (
            <ListItem key={"cloud_backup"} disablePadding>
              <ListItemButton onClick={() => performAction(Actions.CLOUD)}>
                <ListItemIcon>
                  <CloudDoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Downloading changes..."}
                  sx={{
                    marginRight: 4,
                  }}
                />
                <KeyIcon keyName={Actions.CLOUD} />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem key={"cloud_backup"} disablePadding>
              <ListItemButton onClick={() => performAction(Actions.CLOUD)}>
                <ListItemIcon>
                  <CloudDoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Cloud Backup is up to date"}
                  sx={{
                    marginRight: 4,
                  }}
                />
                <KeyIcon keyName={Actions.CLOUD} />
              </ListItemButton>
            </ListItem>
          )
        ) : (
          <ListItem key={"cloud_backup"} disablePadding>
            <ListItemButton onClick={() => performAction(Actions.SETTINGS)}>
              <ListItemIcon>
                <CloudOffIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Set Cloud Backup"}
                sx={{
                  marginRight: 4,
                }}
              />
              <KeyIcon keyName={Actions.CLOUD} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Box sx={{ flexGrow: 1 }}></Box>
      <List>
        <ListItem key={"version"}>
          <ListItemText
            primary={
              <Box
                sx={{
                  textAlign: "center",
                  fontStyle: "italic",
                  color: "text.secondary",
                }}>
                Made haphazardly by{" "}
                <Link
                  to="https://github.com/cjx3711"
                  target="_blank"
                  style={{
                    color: "primary.main",
                    textDecoration: "underline",
                  }}>
                  cjx3711
                </Link>
                <br />
                <Typography component="span" sx={{ color: "text.disabled" }}>
                  v1.1.3
                </Typography>
              </Box>
            }
          />
        </ListItem>
      </List>
    </Stack>
  );
};
